import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import CircleImg from "../Assets/ContactImg/ContactImg/CircleContact.png";
import {
  EmailIcon2,
  FacebookIcon2,
  InstagramIcon2,
  LinkedInIcon2,
  LocationIcon2,
  PhoneCallIcon,
} from "../../res/icons";
import { toast } from "react-toastify";

const inputHeight = "56px";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await fetch('https://api.ipaisa.co.in/myapp/api/contact_us', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,

        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      console.log('Response from API:', result);
      
      if (response.ok) {
        toast.success('Message sent successfully!');
      } else {
        toast.error('Failed to send message.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please try again later.');
    }
  };

  useEffect(() => {
    if (submitted) {
      handleSubmit();
      setSubmitted(false); // Reset the submitted state
    }
  }, [submitted]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <Header />
      <Box sx={{ padding: "7%", paddingBottom: "10%" }}>
        <Grid container spacing={5}>
          {/* Contact Information Section */}
          <Grid item xs={12} md={5}>
            <Paper
              sx={{
                padding: "10% 10% 0% 10%",
                height: "85%",
                borderRadius: "15px",
                background: "#3029D9",
                marginTop: "7%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="h5" color="white" gutterBottom>
                Contact Information
                <Typography
                  variant="body1"
                  paragraph
                  align="start"
                  style={{ color: "rgba(255, 255, 255, 0.5)" }}
                >
                  Say something to start a live chat!
                </Typography>
              </Typography>

              <Box sx={{ color: "white", flexGrow: 1 }}>
                <Box display="flex" alignItems="center" marginBottom={5}>
                  <IconButton>
                    <PhoneCallIcon style={{ marginRight: "10px" }} />
                  </IconButton>
                  <Typography variant="body1">+91 7387774705</Typography>
                </Box>
                <Box display="flex" alignItems="center" marginBottom={5}>
                  <IconButton>
                    <EmailIcon2 style={{ marginRight: "10px" }} />
                  </IconButton>
                  <Typography variant="body1">care@iPaisa.co.in</Typography>
                </Box>
                <Box display="flex" alignItems="center" marginBottom={5}>
                  <IconButton>
                    <LocationIcon2 style={{ marginRight: "10px" }} />
                  </IconButton>
                  <Box>
                    <Typography variant="body1">
                      Edsom Fintech Pvt. Ltd Second Floor,
                    </Typography>
                    <Typography variant="body1">
                      Golden Empire, Baner Pune - 411045
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box display="flex" alignItems="center" id="socialmediaicons" marginBottom='-30%'>
                <IconButton
                  color="inherit"
                  href="https://www.linkedin.com/company/edsom-fintech-pvt-ltd/"
                >
                  <LinkedInIcon2 />
                </IconButton>
                <IconButton
                  color="inherit"
                  href="https://www.facebook.com/share/sWt1cQzoRjGLNYDQ/?mibextid=qi2Omg"
                >
                  <FacebookIcon2 />
                </IconButton>
                <IconButton
                  color="inherit"
                  href="https://www.instagram.com/ipaisa07?igsh=bmk2dWhlNDloZzcz"
                >
                  <InstagramIcon2 />
                </IconButton>
              </Box>
              <img src={CircleImg} alt="" style={{height:'30%', width:'45%', marginLeft:'70%'}}/>
            </Paper>
          </Grid>

          {/* Form Section */}
          <Grid item xs={12} md={7}>
            <Grid
              elevation={3}
              sx={{
                padding: "20px",
                height: "90%",
                borderRadius: "15px",
                marginTop: "6%",
              }}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setSubmitted(true);
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="First Name"
                      name="firstName"
                      variant="outlined"
                      value={formData.firstName}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      name="lastName"
                      variant="outlined"
                      value={formData.lastName}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      variant="outlined"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Phone No"
                      name="phone"
                      variant="outlined"
                      value={formData.phone}
                      onChange={handleInputChange}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: "4%", marginBottom: "4%" }}
                  >
                    <Typography variant="4" fontWeight="bold" color="#3029D9">
                      Select Subject?
                    </Typography>
                    <RadioGroup
                      id="rediogroup"
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="subject"
                      value={formData.subject}
                      onChange={handleInputChange}
                      style={{ paddingTop: "1%" }}
                    >
                      <FormControlLabel
                        value="General Inquiry"
                        control={
                          <Radio
                            sx={{
                              color: "#3029D9",
                              "&.Mui-checked": {
                                color: "#3029D9",
                              },
                            }}
                          />
                        }
                        label="General Inquiry"
                      />
                      <FormControlLabel
                        value="Career Inquiry"
                        control={
                          <Radio
                            sx={{
                              color: "#3029D9",
                              "&.Mui-checked": {
                                color: "#3029D9",
                              },
                            }}
                          />
                        }
                        label="Career Inquiry"
                      />
                      <FormControlLabel
                        value="Product Inquiry"
                        control={
                          <Radio
                            sx={{
                              color: "#3029D9",
                              "&.Mui-checked": {
                                color: "#3029D9",
                              },
                            }}
                          />
                        }
                        label="Product Inquiry"
                      />
                      <FormControlLabel
                        value="Partnership & Business Inquiry"
                        control={
                          <Radio
                            sx={{
                              color: "#3029D9",
                              "&.Mui-checked": {
                                color: "#3029D9",
                              },
                            }}
                          />
                        }
                        label="Partnership & Business Inquiry"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Message"
                      name="message"
                      variant="outlined"
                      placeholder="Tell us how we can help you!"
                      multiline
                      rows={4}
                      value={formData.message}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} display="flex" justifyContent="flex-end">
                    <Button
                      type="submit"
                      style={{
                        backgroundColor: "#3029D9",
                        color: "white",
                        borderRadius: "5px",
                        textTransform: "none",
                        marginTop: "10px",
                        height: inputHeight,
                        fontSize: 15,
                        width: "30%",
                      }}
                    >
                      Send Message
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default ContactUs;
