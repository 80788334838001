// redux/actionTypes.js
export const FETCH_PARTNERS_REQUEST = 'FETCH_PARTNERS_REQUEST';
export const FETCH_PARTNERS_SUCCESS = 'FETCH_PARTNERS_SUCCESS';
export const FETCH_PARTNERS_FAILURE = 'FETCH_PARTNERS_FAILURE';
export const UPDATE_PARTNERS = 'UPDATE_PARTNERS';
export const DELETE_PARTNER = 'DELETE_PARTNER';
export const TOGGLE_PARTNER_STATUS = 'TOGGLE_PARTNER_STATUS';
export const DELETE_PARTNER_FAILURE = 'DELETE_PARTNER_FAILURE';
export const DELETE_PARTNER_SUCCESS = 'DELETE_PARTNER_SUCCESS';

