import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Container,
  Grid,
  TextField,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  CardMedia
} from "@mui/material";
import logo from "../Assets/login/iPaisaLogo.jpg";
import BBPSLogo from "../Assets/BBPSLogo/BBPS.png";


const RRecharge = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      mobileNumber: "",
      operator: "",
      circle: "",
      amount: ""
    },
    validationSchema: Yup.object({
      mobileNumber: Yup.string()
                .matches(/^[0-9]{10}$/, 'Please enter a valid 10-digit mobile number')
                .notOneOf(['0000000000', '1111111111', '2222222222', '3333333333', '4444444444', '5555555555', '6666666666', '7777777777', '8888888888', '9999999999'], 'Mobile number cannot be all the same digit')
                .test('not-all-zeros', 'Mobile number cannot be all zeros', value => value !== '0000000000')
                .test('not-eight-consecutive-zeros', 'Mobile number cannot contain eight consecutive zeros', value => value && !/00000000/.test(value))
                .test('not-all-same-digit', 'Mobile number cannot be all the same digit', value => {
                    if (value) {
                        const uniqueDigits = new Set(value.split(''));
                        return uniqueDigits.size !== 1;
                    }
                    return true;
                })
                .required('Mobile number is required'),
      operator: Yup.string().required("Operator is required"),
      circle: Yup.string().required("Circle is required"),
      amount: Yup.string()
        .matches(/^[1-9][0-9]*$/, "Amount must be a positive number without leading zeros")
        .required("Amount is required")
    }),
    onSubmit: async (values) => {
      // Simulate API call or handle form submission logic here
      setSubmitSuccess(true);
    }
  });

  const handleKeyPress = (e) => {
    const charCode = e.charCode || e.keyCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  const inputHeight = '56px'; 

  return (
    <Container maxWidth="lg" style={{marginTop:'7%', marginBottom:'2%'}}>
      <Box mt={4} p={4} boxShadow={2} bgcolor="#DDF1F5" borderRadius={8}
       sx={{
        background: 'linear-gradient(135deg, #0B1E59 0%, #1840BF 100%)',
        color: 'white',
      }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} style={{marginTop:"1%"}}>
          <CardMedia
              component="img"
              image={logo}
              alt="iPaisa Logo"
              style={{ backgroundColor: "transparent", borderRadius: "50%", marginBottom:'4%' }}
              sx={{
                height: { xs: 50, md: 90 },
                width: { xs: 50, md: 90 },
                mt: { xs: 1, md: 1 },
                marginRight: { xs: "10px", md: "10px" }, // Adjusted to ensure space between logo and text
              }}
            />
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', fontSize:'35px' }}> 
              Instant Prepaid Mobile Recharge Solution
            </Typography>
            <Typography variant="body1" gutterBottom sx={{fontSize:'20px', marginTop:'5%'}}>
              Empower your connectivity with seamless prepaid mobile recharges, where convenience meets innovation.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box p={4} bgcolor="white" boxShadow={2} borderRadius={8}>
              <Typography variant="h5" color='#027BFE' gutterBottom sx={{ fontWeight: 'bold', fontSize:'30px'}}>
                Mobile Recharge
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <img src={BBPSLogo} alt="BBPS Logo" style={{ marginLeft: '25.5rem', width: '13%', height: '20%' }} />
              </Box>
              <FormControl fullWidth margin="normal" error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}>
                <TextField
                  id="mobileNumber"
                  name="mobileNumber"
                  label="Mobile Number"
                  variant="outlined"
                  value={formik.values.mobileNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
                  helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
                  InputProps={{
                    inputProps: {
                      maxLength: 10,
                      pattern: "[0-9]*",
                      title: "Please enter a 10-digit number"
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />
              </FormControl>
              <FormControl fullWidth margin="normal" error={formik.touched.operator && Boolean(formik.errors.operator)}>
                <InputLabel id="operator-label">Operator</InputLabel>
                <Select
                  labelId="operator-label"
                  id="operator"
                  name="operator"
                  label="Operator"
                  value={formik.values.operator}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.operator && Boolean(formik.errors.operator)}
                >
                  <MenuItem value="">Select Operator</MenuItem>
                  <MenuItem value="Airtel">Airtel</MenuItem>
                  <MenuItem value="BSNL">BSNL</MenuItem>
                  <MenuItem value="Jio">Jio</MenuItem>
                  <MenuItem value="MTNL">MTNL</MenuItem>
                  <MenuItem value="Vi">Vi</MenuItem>
                  <MenuItem value="Tata Docomo CDMA Postpaid">Tata Docomo</MenuItem>
                  <MenuItem value="Tata Indicom">Tata Indicom</MenuItem>
                  <MenuItem value="Reliance Communications">Reliance Communications</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal" error={formik.touched.circle && Boolean(formik.errors.circle)}>
                <InputLabel id="circle-label">Circle</InputLabel>
                <Select
                  labelId="circle-label"
                  id="circle"
                  name="circle"
                  label="Circle"
                  value={formik.values.circle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.circle && Boolean(formik.errors.circle)}
                >
                 <MenuItem value="">Select Circle</MenuItem>
                  <MenuItem value="Andhra Pradesh">Andhra Pradesh</MenuItem>
                  <MenuItem value="Assam">Assam</MenuItem>
                  <MenuItem value="Bihar">Bihar</MenuItem>
                  <MenuItem value="Chennai">Chennai</MenuItem>
                  <MenuItem value="Delhi">Delhi</MenuItem>
                  <MenuItem value="Gujarat">Gujarat</MenuItem>
                  <MenuItem value="Haryana">Haryana</MenuItem>
                  <MenuItem value="Himachal Pradesh">Himachal Pradesh</MenuItem>
                  <MenuItem value="Jammu & Kashmir">Jammu & Kashmir</MenuItem>
                  <MenuItem value="Karnataka">Karnataka</MenuItem>
                  <MenuItem value="Kerala">Kerala</MenuItem>
                  <MenuItem value="Kolkata">Kolkata</MenuItem>
                  <MenuItem value="Madhya Pradesh">Madhya Pradesh</MenuItem>
                  <MenuItem value="Maharashtra">Maharashtra</MenuItem>
                  <MenuItem value="Mumbai">Mumbai</MenuItem>
                  <MenuItem value="North East">North East</MenuItem>
                  <MenuItem value="Odisha">Odisha</MenuItem>
                  <MenuItem value="Punjab">Punjab</MenuItem>
                  <MenuItem value="Rajasthan">Rajasthan</MenuItem>
                  <MenuItem value="Tamil Nadu">Tamil Nadu</MenuItem>
                  <MenuItem value="Uttar Pradesh (East)">Uttar Pradesh (East)</MenuItem>
                  <MenuItem value="Uttar Pradesh (West)">Uttar Pradesh (West)</MenuItem>
                  <MenuItem value="West Bengal">West Bengal</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal" error={formik.touched.amount && Boolean(formik.errors.amount)}>
                <TextField
                  id="amount"
                  name="amount"
                  label="Amount"
                  variant="outlined"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.amount && Boolean(formik.errors.amount)}
                  helperText={formik.touched.amount && formik.errors.amount}
                  InputProps={{
                    inputProps: {
                      pattern: "[0-9]*",
                      title: "Please enter a valid amount"
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />
              </FormControl>
              <Button
                fullWidth
                type="submit"
                onClick={formik.handleSubmit}
                style={{ 
                  marginTop: "4%", 
                  backgroundColor: '#027BFE', 
                  color: 'white', 
                  textTransform: 'none', 
                  height: inputHeight ,
                  fontSize: 20
                }}
              >
                Confirm
              </Button>
              {submitSuccess && (
                <Typography variant="body1" style={{ marginTop: "0.5rem", color: "green" }}>
                  Recharge successfully submitted!
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default RRecharge;
