import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper, Typography, TextField, Button } from '@mui/material';
import * as Yup from 'yup';
import axios from 'axios';
import { Formik, Form } from 'formik';
import NewFTRTable from './NewFTRTable';
import { PartnerArrowIcon } from '../../res/NewWebIcons';

const NewFundTReport = () => {
  const [debitData, setDebitData] = useState([]);
  const [creditData, setCreditData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const validationSchema = Yup.object({
    fromDate: Yup.date().required('Required'),
    toDate: Yup.date().required('Required'),
  });

  const fetchDebitData = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.get('https://api.ipaisa.co.in/myapp/auth/wtransReportDebt', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDebitData(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching debit data:', error);
      setError(error.message);
    }
  };

  const fetchCreditData = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.get('https://api.ipaisa.co.in/myapp/auth/wtransReportCred', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCreditData(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching credit data:', error);
      setError(error.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchDebitData();
    fetchCreditData();
    setLoading(false);
  }, []);

  const handleInputChange = (event, setFieldValue) => {
    const { name, value } = event.target;
    setFieldValue(name, value);
  };

  const handleSearch = (values) => {
    try {
      const { fromDate, toDate } = values;
      const from = new Date(fromDate);
      const to = new Date(toDate);

      // Debugging: Log the fromDate, toDate, and the parsed dates
      console.log('fromDate (raw):', fromDate, 'toDate (raw):', toDate);
      console.log('fromDate (parsed):', from, 'toDate (parsed):', to);

      if (Array.isArray(debitData) && Array.isArray(creditData)) {
        const filteredDebitData = debitData.filter((item) => {
          const itemDate = new Date(item.date);
          return itemDate >= from && itemDate <= to;
        });

        const filteredCreditData = creditData.filter((item) => {
          const itemDate = new Date(item.date);
          return itemDate >= from && itemDate <= to;
        });

        const combinedFilteredData = [...filteredDebitData, ...filteredCreditData];

        // Debugging: Log the filtered data
        console.log('Filtered Debit Data:', filteredDebitData);
        console.log('Filtered Credit Data:', filteredCreditData);
        console.log('Combined Filtered Data:', combinedFilteredData);

        setFilteredData(combinedFilteredData);
      } else {
        console.error('debitData or creditData is not an array');
      }
    } catch (errors) {
      console.log('Form validation errors:', errors);
    }
  };



  return (
    <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
      <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
        <Typography color="textSecondary" style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>
          Report
        </Typography>
        <PartnerArrowIcon />
        <Typography color="textSecondary" style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>
          Fund Transfer Report
        </Typography>
      </Grid>

      <Grid style={{ marginTop: '1%' }}>
        <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>
          Fund Transfer Report
        </Typography>
      </Grid>

      <Grid style={{ marginTop: '3%' }}>
        <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
          <Formik
            initialValues={{ fromDate: '', toDate: '' }}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSearch(values)}
          >
            {({ errors, touched, setFieldValue }) => (
              <Form>
                <Grid container spacing={2} sx={{ marginTop: { xs: '0%', md: '1%' } }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="from-date"
                      name="fromDate"
                      label="From Date"
                      type="date"
                      onChange={(event) => handleInputChange(event, setFieldValue)}
                      InputLabelProps={{ shrink: true }}
                      error={touched.fromDate && Boolean(errors.fromDate)}
                      helperText={touched.fromDate && errors.fromDate}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="to-date"
                      name="toDate"
                      label="To Date"
                      type="date"
                      onChange={(event) => handleInputChange(event, setFieldValue)}
                      InputLabelProps={{ shrink: true }}
                      error={touched.toDate && Boolean(errors.toDate)}
                      helperText={touched.toDate && errors.toDate}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', marginTop: { xs: '0%', md: '1%' } }}>
                    <Button
                      sx={{
                        height: '50px',
                        fontSize: '16px',
                        textTransform: 'none',
                        width: { xs: '100%', md: '18%' },
                        borderRadius: '8px',
                        backgroundColor: '#027BFE',
                        color: '#FFFFFF',
                        '&:hover': { backgroundColor: '#027BFE', color: '#FFFFFF' },
                      }}
                      type="submit"
                      fullWidth
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>

      {loading && <Typography variant="h6" sx={{ textAlign: 'center', mt: 2 }}>Loading...</Typography>}
      {error && <Typography color="error" sx={{ textAlign: 'center', mt: 2 }}>{error}</Typography>}
      {!loading && !error && (
        <NewFTRTable
          debitData={filteredData.length ? filteredData : debitData}
          creditData={filteredData.length ? filteredData : creditData}
        />

      )}
    </Container>
  );
};

export default NewFundTReport;
