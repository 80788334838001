import React, { useState, useEffect } from "react";
import axios from "axios";
import { Grid, Card, CardContent, Typography, CardMedia, Paper, Container, DialogTitle } from '@material-ui/core';
import PieChartComponent from './PieChartComponent';
// import { useDevice } from "react-detect-device";
import apibalance from '../Assets/Dashboard/apiBalance.svg';
import walletbalance from '../Assets/Dashboard/walletBalance.svg';
import iwalletbalance from '../Assets/Dashboard/iWallet.svg';
import iatmbalance from '../Assets/Dashboard/iAtm.svg';
import morqueimg from '../Assets/Dashboard/morqueimg.jpg';

import "@fontsource/roboto";
import { Button, Dialog, DialogActions, DialogContent, IconButton, TextField } from "@mui/material";
import { AddCardIcon, AUBankIcon, AxisBankIcon, IDFCBankIcon, UnionBankIcon, YesBankIcon } from "../../res/icons";



const Dashboard = () => {
    const userType = localStorage.getItem("uType");
    const [walletBalance, setWalletBalance] = useState(0);
    const [partnerCount, setPartnerCount] = useState({
        channelPartnerCount: 0,
        superDistributerCount: 0,
        masterDistributerCount: 0,
        areaDistributerCount: 0,
        retailerCount: 0,
        userCount: 0,
    });
    const [activeCardIndex, setActiveCardIndex] = useState(0);
    const [openDialog, setOpenDialog] = useState(false); // State to control the dialog visibility
    const [formData, setFormData] = useState({
        bankName: '',
        companyName: '',
        accountNo: '',
        ifsc: '',
        branch: '',
        bankImage: null, // For uploading the bank image
    });

    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    
    };

    // Handle image upload
    const handleImageUpload = (e) => {
        setFormData((prev) => ({ ...prev, bankImage: e.target.files[0] }));
    };

    // Handle dialog open
    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    // Handle dialog close
    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    // Handle form submission
    const handleSubmit = () => {
        const newCard = {
            bankName: formData.bankName,
            company: formData.companyName,
            accountNo: formData.accountNo,
            ifsc: formData.ifsc,
            branch: formData.branch,
            icon: UnionBankIcon, // Replace with default or uploaded icon if necessary
            color: '#' + Math.floor(Math.random() * 16777215).toString(16) // Random color generation
        };
        // Handle form submission logic here
        console.log(cardsData);
        setCardsData((prevCards) => [...prevCards, newCard]);
        console.log(cardsData);
        console.log('Form Data:', formData);
        setOpenDialog(false);
    };
        const handleViewDetails = (index) => {
            setActiveCardIndex(index);
        };

    // const [partnerData, setPartnerData] = useState({
    //     channelPartnerCount: 0,
    //     superDistributerCount: 0,
    //     masterDistributerCount: 0,
    //     areaDistributerCount: 0,
    //     retailerCount: 0,
    //     userCount: 0,
    // });

    // const cardsData = [
    //     // {
    //     //     bankName: "YES BANK",
    //     //     company: "EDSOM FINTECH PVT.LTD.",
    //     //     accountNo: "120664700000021",
    //     //     ifsc: "YESB0000008",
    //     //     branch: "Prabhat Road, Pune",
    //     //     icon: YesBankIcon, // Use the desired icon
    //     //     color: "#0002D8",
    //     // },
    //     {
    //         bankName: "UNION BANK",
    //         company: "EDSOM FINTECH PVT.LTD.",
    //         accountNo: "583801010050602",
    //         ifsc: "UBIN0558389",
    //         branch: "Baner, Pune",
    //         icon: UnionBankIcon, // Use the desired icon
    //         color: "#D81F40"
    //     },
    //     {
    //         bankName: "AU SMALL Finance Bank",
    //         company: "EDSOM FINTECH PVT.LTD.",
    //         accountNo: "2402235358748585",
    //         ifsc: "AUBL0002353",
    //         branch: "Prabhat Road, Pune",
    //         icon: AUBankIcon, // Use the desired icon
    //         color: "#660F59"
    //     },
    //     {
    //         bankName: "AXIS Bank",
    //         company: "EDSOM FINTECH PVT.LTD.",
    //         accountNo: " 919020076135843",
    //         ifsc: "UTIB0002754",
    //         branch: "Balewadi, Pune",
    //         icon: AxisBankIcon, // Use the desired icon
    //         color: "#721324"
    //     },
    //     {
    //         bankName: "IDFC Bank",
    //         company: "EDSOM FINTECH PVT.LTD.",
    //         accountNo: "10066469212",
    //         ifsc: "IDFB0041433",
    //         branch: "Balewadi, Pune",
    //         icon: IDFCBankIcon, // Use the desired icon
    //         color: "#991F35"
    //     },
    // ];

    const [cardsData, setCardsData] = useState([
        {
            bankName: "YES BANK",
            company: "EDSOM FINTECH PVT.LTD.",
            accountNo: "120664700000021",
            ifsc: "YESB0000008",
            branch: "Prabhat Road, Pune",
            icon: YesBankIcon, // Use the desired icon
            color: "#0002D8",
         },
        {
            bankName: "UNION BANK",
            company: "EDSOM FINTECH PVT.LTD.",
            accountNo: "583801010050602",
            ifsc: "UBIN0558389",
            branch: "Baner, Pune",
            icon: UnionBankIcon, // Use the desired icon
            color: "#D81F40"
        },
        {
            bankName: "AU SMALL Finance Bank",
            company: "EDSOM FINTECH PVT.LTD.",
            accountNo: "2402235358748585",
            ifsc: "AUBL0002353",
            branch: "Prabhat Road, Pune",
            icon: AUBankIcon, // Use the desired icon
            color: "#660F59"
        },
        {
            bankName: "AXIS Bank",
            company: "EDSOM FINTECH PVT.LTD.",
            accountNo: " 919020076135843",
            ifsc: "UTIB0002754",
            branch: "Balewadi, Pune",
            icon: AxisBankIcon, // Use the desired icon
            color: "#721324"
        },
        {
            bankName: "IDFC Bank",
            company: "EDSOM FINTECH PVT.LTD.",
            accountNo: "10066469212",
            ifsc: "IDFB0041433",
            branch: "Balewadi, Pune",
            icon: IDFCBankIcon, // Use the desired icon
            color: "#991F35"
        },
    ]);
    
    const fetchBalance = async () => {
        try {
            const token = localStorage.getItem("jwtToken");
            const response = await axios.get(
                "https://api.ipaisa.co.in/myapp/auth/getwalletbalance",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setWalletBalance(response.data.walletBalance || 0);
        } catch (error) {
            console.error("Error fetching wallet balance:", error);
        }
    };

    const fetchPartnerData = async () => {
        try {
            const token = localStorage.getItem("jwtToken");
            const response = await axios.get('https://api.ipaisa.co.in/myapp/auth/count', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = response.data.data; // Adjusted to match response structure
            setPartnerCount({
                channelPartnerCount: data.CHANNELPARTNER || 0,
                superDistributerCount: data.SUPERDISTRIBUTOR || 0,
                masterDistributerCount: data.MASTERDISTRIBUTOR || 0,
                areaDistributerCount: data.AREADISTRIBUTOR || 0,
                retailerCount: data.RETAILER || 0,
                userCount: data.USER || 0,
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        fetchBalance();
        fetchPartnerData();
    }, []);

    const username = localStorage.getItem("username");
    const date = new Date();
    const hour = date.getHours();
    let greeting;

    if (hour >= 16) {
        greeting = "Good Evening";
    } else if (hour >= 12) {
        greeting = "Good Afternoon";
    } else {
        greeting = "Good Morning";
    }

    const cardData = [
        { label: "Channel Partners", count: partnerCount.channelPartnerCount, color: "#1abc9c" },
        { label: "Super Distributors", count: partnerCount.superDistributerCount, color: "#3498db" },
        { label: "Master Distributors", count: partnerCount.masterDistributerCount, color: "#9b59b6" },
        { label: "Area Distributors", count: partnerCount.areaDistributerCount, color: "#e74c3c" },
        { label: "Retailers", count: partnerCount.retailerCount, color: "#f39c12" },
        { label: "Users", count: partnerCount.userCount, color: "#34495e" },
    ];

    const filterCards = (userType) => {
        switch (userType) {
            case 'ADMIN':
                return cardData; // Show all 6 cards
            case 'CHANNELPARTNER':
                return cardData.slice(1); // Show cards from index 1 to the end
            case 'SUPERDISTRIBUTOR':
                return cardData.slice(2); // Show cards from index 2 to the end
            case 'MASTERDISTRIBUTOR':
                return cardData.slice(3); // Show cards from index 3 to the end
            case 'AREADISTRIBUTOR':
                return cardData.slice(4); // Show cards from index 4 to the end
            default:
                return [];
        }
    };

    // const filteredCardData = filterCards(userType);



    const filteredCardData = filterCards(userType);
    console.log(filteredCardData);

    // const device = useDevice();
    // const isMobile = device.isMobile;

    return (
        <Grid>
            <Grid style={{ margin: "2%" }}>
                <Grid container spacing={2} style={{ marginTop: '10%' }}>
                    <marquee
                        style={{
                            backgroundColor: "#031273",
                            padding: "10px",
                            color: "#000",
                            fontSize: "18px",
                            fontWeight: "bold",
                            color: '#ffffff',
                        }}
                    >
                        Hello {username}.Uncover the Latest Insights — {"  "}
                        <a
                            href={morqueimg}
                            rel="noopener noreferrer"
                            style={{
                                color: "Red",
                                textDecoration: "none",
                                animation: "blink 1s step-end infinite",
                            }}
                        >
                            Click Here
                        </a>
                    </marquee>
                    <style>
                        {`
                    @keyframes blink {
                        0% { opacity: 1; }
                        50% { opacity: 0; }
                        100% { opacity: 1; }
                    }
                    `}
                    </style>
                    <style>
                        {`
                        @keyframes blink {
                        0% { opacity: 1; }
                        50% { opacity: 0; }
                        100% { opacity: 1; }
                        }
                    `}
                    </style>
                    <style>
                        {`
                                @keyframes blink {
                                0% { opacity: 1; }
                                50% { opacity: 0; }
                                100% { opacity: 1; }
                                }
                                `}
                    </style>
                    <Grid item xs={12} style={{ paddingLeft: '3%' }}>
                        <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                            {greeting}, {username}
                        </Typography>
                        <Typography variant="body1" color="textSecondary" style={{ marginBottom: '1%' }}>
                            Welcome back! Ready to make today amazing, Let's get started!
                        </Typography>
                    </Grid>

                    {userType === "ADMIN" ? <>
                        <Grid item xs={12} sm={6} md={3}>
                            <Card style={{ padding: '32px 30px', borderRadius: '20px', width: '65%', marginLeft: '8%', backgroundColor: '#00178D', color: 'white' }}>
                                <Grid container spacing={2} direction="column" alignItems="flex-start">
                                    <Grid item style={{ width: '60px', height: '60px' }}>
                                        <CardMedia
                                            style={{ borderRadius: '6px', width: '100%', height: '100%', fontSize: '24px' }}
                                            component="img"
                                            alt="API Balance"
                                            src={apibalance}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography color="white" style={{ fontFamily: 'Roboto' }} gutterBottom>
                                            API Balance
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h5" component="h2" style={{ textAlign: 'start', fontFamily: 'Roboto' }}>
                                            ₹ 1,50,000.00
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </>
                        : userType === 'RETAILER' ?
                            <>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Card style={{ padding: '32px 30px', borderRadius: '20px', width: '65%', marginLeft: '8%', backgroundColor: '#FF8C00', color: 'white' }}>
                                        <Grid container spacing={2} direction="column" alignItems="flex-start">
                                            <Grid item style={{ width: '60px', height: '60px' }}>
                                                <CardMedia
                                                    style={{ borderRadius: '6px', width: '100%', height: '100%', fontSize: '24px' }}
                                                    component="img"
                                                    alt="iWallet"
                                                    src={iwalletbalance}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography color="white" style={{ fontFamily: 'Roboto' }} gutterBottom>
                                                    iIT
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h5" component="h2" style={{ textAlign: 'start', fontFamily: 'Roboto' }}>
                                                    ₹ 0.00
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Card style={{ padding: '32px 30px', borderRadius: '20px', width: '65%', marginLeft: '8%', backgroundColor: '#77279D', color: 'white' }}>
                                        <Grid container spacing={2} direction="column" alignItems="flex-start">
                                            <Grid item style={{ width: '60px', height: '60px' }}>
                                                <CardMedia
                                                    style={{ borderRadius: '6px', width: '100%', height: '100%', fontSize: '24px' }}
                                                    component="img"
                                                    alt="iATM"
                                                    src={iatmbalance}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography color="white" style={{ fontFamily: 'Roboto' }} gutterBottom>
                                                    iATM
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h5" component="h2" style={{ textAlign: 'start', fontFamily: 'Roboto' }}>
                                                    ₹0.00
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </>
                            :
                            <></>
                    }
                    <Grid item xs={12} sm={6} md={3}>
                        <Card style={{ padding: '32px 30px', borderRadius: '20px', width: '65%', marginLeft: '8%', backgroundColor: '#008D39', color: 'white' }}>
                            <Grid container spacing={2} direction="column" alignItems="flex-start">
                                <Grid item style={{ width: '60px', height: '60px' }}>
                                    <CardMedia
                                        style={{ borderRadius: '6px', width: '100%', height: '100%', fontSize: '24px' }}
                                        component="img"
                                        alt="Wallet Balance"
                                        src={walletbalance}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography color='white' style={{ fontFamily: 'Roboto' }} gutterBottom>
                                        Wallet Balance
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" component="h2" style={{ textAlign: 'start', fontFamily: 'Roboto' }}>
                                        ₹ {walletBalance.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>

                {userType !== "RETAILER" && (
                    <Container maxWidth="false" style={{ marginTop: '2%', width: "100%", justifyContent: "flex-start", marginRight: '25%' }}>
                        <Grid container spacing={2} >

                            <Paper style={{
                                padding: '20px',
                                textAlign: 'center',
                                backgroundColor: '#FFFFFF',
                                borderRadius: 20,
                                height: '350px',
                                width: 'calc(100% - 4%)',
                                marginBottom: '16px',
                                display: 'flex',


                            }}>
                                <Grid container direction="column">
                                    <Grid item spacing={2} style={{ marginTop: '4%' }}>
                                        <Typography variant="h2" style={{ fontSize: '20px', fontWeight: 'bold', marginRight: '75%' }}>
                                            Records
                                        </Typography>
                                    </Grid>

                                    <Grid container spacing={10} style={{ display: "flex", justifyContent: 'space-evenly', flexDirection: 'row', width: "100%", height: "100%", marginTop: '4%' }}>
                                        {filteredCardData.map((card, index) => (
                                            <Grid item md={4} lg={2} key={index} style={{ textAlign: 'center', justifyContent: 'space-evenly' }}>
                                                <Grid container direction="column" style={{
                                                    width: "140%",
                                                    height: "75%",
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    backgroundColor: card.color,
                                                    color: 'white',
                                                    borderRadius: "15px",
                                                    marginTop: '6%',
                                                    marginLeft: '-50%'
                                                }}>
                                                    <Grid item style={{ marginBottom: '5%' }}>
                                                        <Typography variant="body1" style={{ fontSize: '30px', fontWeight: 'bold' }}>
                                                            {card.count}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item style={{ alignItems: "center", alignSelf: "center", textAlign: 'center' }}>
                                                        <Typography variant="body1">
                                                            {card.label}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>


                            </Paper>
                        </Grid>
                    </Container>
                )}

            
                <Grid container spacing={1} style={{ marginTop: '2%', width: '100%', marginRight: '10%' }}>
                    <Grid item xs={12}>
                        <PieChartComponent />
                    </Grid>
                </Grid>

                <Container maxWidth="false" style={{ marginTop: '2%', width: '100%', justifyContent: 'flex-start', marginRight: '25%' }}>
                    <Grid container spacing={2} direction="row" justifyContent="center">
                        <Paper
                        elevation={4}
                        style={{
                            marginTop: '2%',
                            padding: '0.6%',
                            textAlign: 'center',
                            backgroundColor: '#FFFFFF',
                            borderRadius: 20,
                            width: 'calc(100% - 3%)',
                            marginBottom: '4%',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            padding: '2%',
                        }}
                        >
                        <Grid
                            style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            }}
                        >
                            <Typography style={{ fontWeight: 'bold', fontSize: '20px' }}>My Banks</Typography>
                            {userType === 'ADMIN' && (
                            <IconButton onClick={handleDialogOpen} style={{ cursor: 'pointer' }}>
                                <AddCardIcon />
                            </IconButton>
                             )}
                        </Grid>

                        {/* Existing Cards Rendering Logic */}
                        <Grid
                            style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            }}
                        >
                            {cardsData.map((card, index) => (
                            <Card
                                key={index}
                                style={{
                                backgroundColor: card.color,
                                width: activeCardIndex === index ? '25%' : '12%',
                                borderRadius: '20px',
                                display: 'flex',
                                flexDirection: activeCardIndex === index ? 'column' : 'column',
                                alignItems: activeCardIndex === index ? 'flex-start' : 'center',
                                textAlign: activeCardIndex === index ? 'start' : 'center',
                                padding: '10px',
                                marginBottom: '16px',
                                marginTop: '16px',
                                minHeight: '100%',
                                transition: 'width 0.3 ease-in-out',
                                }}
                            >
                                <CardContent style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                <Grid style={{ display: 'flex', alignItems: 'center', flexDirection: activeCardIndex === index ? 'row' : 'column' }}>
                                    <card.icon style={{ marginRight: activeCardIndex === index ? '10px' : '0', color: '#FFFFFF' }} />
                                    <Typography
                                    variant="body1"
                                    style={{
                                        color: '#FFFFFF',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        paddingLeft: activeCardIndex === index ? '5%' : '0',
                                        marginTop: activeCardIndex === index ? '0' : '10px',
                                    }}
                                    >
                                    {card.bankName}
                                    </Typography>
                                </Grid>
                                {activeCardIndex === index && (
                                    <>
                                    <Typography
                                        variant="body1"
                                        style={{
                                        marginTop: '5%',
                                        color: 'yellow',
                                        fontSize: '15px',
                                        fontWeight: 'bold',
                                        }}
                                    >
                                        {card.company}
                                    </Typography>
                                    <Typography variant="body1" style={{ color: 'white', fontSize: '15px', paddingTop: '4%', fontWeight: 'bold' }}>
                                        Account No: {card.accountNo}
                                    </Typography>
                                    <Typography variant="body1" style={{ color: 'white', fontSize: '13px', paddingTop: '2%' }}>
                                        IFSC: {card.ifsc}
                                    </Typography>
                                    <Typography variant="body1" style={{ color: 'white', fontSize: '13px', paddingTop: '2%' }}>
                                        Branch: {card.branch}
                                    </Typography>
                                    </>
                                )}
                                {activeCardIndex !== index && (
                                    <Button
                                    variant="outlined"
                                    onClick={() => handleViewDetails(index)}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                        color: 'white',
                                        border: '1px solid white',
                                        marginTop: 'auto',
                                        alignSelf: 'center',
                                        fontSize: '10px',
                                    }}
                                    >
                                    View Details
                                    </Button>
                                )}
                                </CardContent>
                            </Card>
                            ))}
                        </Grid>

                        {/* Dialog for adding a new bank */}
                        <Dialog open={openDialog} onClose={handleDialogClose}>
                            <DialogTitle>Add New Bank</DialogTitle>
                            <DialogContent>
                            <TextField
                                label="Enter Bank Name"
                                name="bankName"
                                fullWidth
                                value={formData.bankName}
                                onChange={handleInputChange}
                                margin="dense"
                            />
                            {/* Bank Image Upload */}
                            <Button variant="outlined" component="label" style={{ marginBottom: '16px' }}>
                                Upload Bank Image
                                <input type="file" hidden onChange={handleImageUpload} />
                            </Button>
                            <TextField
                                label="Company Name"
                                name="companyName"
                                fullWidth
                                value={formData.companyName}
                                onChange={handleInputChange}
                                margin="dense"
                            />
                            <TextField
                                label="Account No."
                                name="accountNo"
                                fullWidth
                                value={formData.accountNo}
                                onChange={handleInputChange}
                                margin="dense"
                            />
                            <TextField
                                label="IFSC Code"
                                name="ifsc"
                                fullWidth
                                value={formData.ifsc}
                                onChange={handleInputChange}
                                margin="dense"
                            />
                            <TextField
                                label="Branch"
                                name="branch"
                                fullWidth
                                value={formData.branch}
                                onChange={handleInputChange}
                                margin="dense"
                            />
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={handleDialogClose} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={handleSubmit} color="primary">
                                Submit
                            </Button>
                            </DialogActions>
                        </Dialog>
                        </Paper>
                    </Grid>
                </Container>

                {/* <Grid >
                    <Grid style={{backgroundColor:'red'}}>
                        <Typography>
                            Hello
                        </Typography>
                    </Grid>
                </Grid> */}

            </Grid>
        </Grid>
    );
}

export default Dashboard;
