import React from 'react';
import { Container, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, TablePagination, Grid, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { makeStyles } from '@mui/styles';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import iPaisaLogo from '../Assets/iPaisaLogo/iPaisaLogo.jpg'; // Adjust this path if needed


const useStyles = makeStyles((theme) => ({
    tableRow: {
        '& > *': {
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
    },
    evenRow: {
        backgroundColor: '#D0EFFF',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    },
    creditText: {
        color: 'green',
    },
    debitText: {
        color: 'red',
    },
}));

const NewFTRTable = ({ debitData, creditData }) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // Combine debit and credit data and sort by date (optional)
    const data = [...debitData, ...creditData].sort((a, b) => new Date(b.date) - new Date(a.date));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const generatePDF = () => {
        const doc = new jsPDF();
        const logo = new Image();
        logo.src = iPaisaLogo;

        logo.onload = () => {
            doc.addImage(logo, 'PNG', 10, 10, 30, 30); // Adjust position and size as needed
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(16);
            doc.text('Transaction Statement', 50, 20);
            doc.setFontSize(12);
            doc.text('24 Jun, 2024 - 24 Jul, 2024', 50, 30);

            const tableColumn = ["Date", "Transaction Details", "Type", "Amount"];
            const tableRows = [];

            data.forEach(row => {
                const rowData = [
                    new Date(row.date).toLocaleString(),
                    row.transactionType === 'DEBIT'
                        ? `Paid to ${row.senderName}\nTransaction ID ${row.tranxid}\nUTR No. ${row.remark}\nPaid by ${row.senderNumber}`
                        : `Received from ${row.senderName}\nTransaction ID ${row.tranxid}\nUTR No. ${row.remark}\nCredited to ${row.senderNumber}`,
                    row.transactionType,
                    `${row.amount.toFixed(2)}`
                ];
                tableRows.push(rowData);
            });

            doc.autoTable({
                head: [tableColumn],
                body: tableRows,
                startY: 50,
                styles: {
                    fontSize: 10,
                    cellPadding: 3,
                    overflow: 'linebreak',
                },
                headStyles: {
                    fillColor: [22, 160, 133],
                    textColor: [255, 255, 255],
                    fontSize: 12,
                },
            });

            doc.save('Transaction_Statement.pdf');
        };
    };
    return (

        <Grid sx={{ marginTop: '3%' }}>
            <Grid>
                <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1%' }}>
                    <Grid>
                        <Typography sx={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '25px', color: '#343C6A' }}>History</Typography>
                    </Grid>

                    <Button style={{ textTransform: 'none', backgroundColor: '#027BFE', color: '#FFFFFF', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'sans-serif', gap: '5%' }}
                        onClick={generatePDF}
                        sx={{ width: { xs: '60%', md: '20%' }, fontSize: { xs: '9px', md: '14px' } }}
                    >
                        <DownloadIcon />
                        Download PDF File</Button>
                </Grid>
            </Grid>

            <TableContainer component={Paper} sx={{ marginTop: '2%', maxWidth: '100%', overflowX: 'auto', borderRadius: '12px 12px 0px 0px' }}>
                <Table>
                    <TableHead sx={{ backgroundColor: '#027BFE', color: 'white' }}>
                        <TableRow>
                            <TableCell sx={{ color: 'white', fontSize: { xs: '9px', sm: '10px', md: '11px' } }} align="start">Sr. No.</TableCell>
                            <TableCell sx={{ color: 'white', fontSize: { xs: '9px', sm: '10px', md: '11px' } }} align="start">Sender Name</TableCell>
                            <TableCell sx={{ color: 'white', fontSize: { xs: '9px', sm: '10px', md: '11px' } }} align="center">Sender ID</TableCell>
                            <TableCell sx={{ color: 'white', fontSize: { xs: '9px', sm: '10px', md: '11px' } }} align="start">Transaction ID</TableCell>
                            <TableCell sx={{ color: 'white', fontSize: { xs: '9px', sm: '10px', md: '11px' } }} align="start">Amount</TableCell>
                            <TableCell sx={{ color: 'white', fontSize: { xs: '9px', sm: '10px', md: '11px' } }} align="start">Date</TableCell>
                            <TableCell sx={{ color: 'white', fontSize: { xs: '9px', sm: '10px', md: '11px' } }} align="start">Remark</TableCell>
                            <TableCell sx={{ color: 'white', fontSize: { xs: '9px', sm: '10px', md: '11px' } }} align="center">Sender Number</TableCell>
                            <TableCell sx={{ color: 'white', fontSize: { xs: '9px', sm: '10px', md: '11px' } }} align="center">Transaction Type</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                            <TableRow
                                key={index}
                                className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                <TableCell align="start" sx={{ fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>{index + 1}</TableCell>
                                <TableCell align="start" sx={{ fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>{row.senderName}</TableCell>
                                <TableCell align="center" sx={{ fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>{row.senderId}</TableCell>
                                <TableCell align="start" sx={{ fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>{row.tranxid}</TableCell>
                                <TableCell align="start" sx={{ fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>{`₹${row.amount.toFixed(2)}`}</TableCell>
                                <TableCell align="start" sx={{ fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>{new Date(row.date).toLocaleDateString()}</TableCell>
                                <TableCell align="start" sx={{ fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>{row.remark}</TableCell>
                                <TableCell align="center" sx={{ fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>{row.senderNumber}</TableCell>
                                <TableCell
                                    align="center" sx={{ fontSize: { xs: '9px', sm: '10px', md: '11px' } }}
                                    className={row.transactionType === 'CREDIT' ? classes.creditText : classes.debitText}
                                >
                                    {row.transactionType}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Grid>
    )
}

export default NewFTRTable
