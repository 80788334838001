// import React, {useState, useEffect} from 'react'
// import NewPayInOutTopComp from './NewPayInOutTopComp'
// import SearchIcon from "@mui/icons-material/Search";
// import { Button, Container, Grid, IconButton, InputBase, Typography, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper} from '@mui/material';
// import { DownloadIcon, PartnerArrowIcon } from '../../res/NewWebIcons';
// import CallMadeIcon from '@mui/icons-material/CallMade';
// import { makeStyles } from '@mui/styles';
// import axios from 'axios';
// import iPaisaLogo from '../Assets/iPaisaLogo/iPaisaLogo.jpg'; // Adjust this path if needed
// import jsPDF from 'jspdf';
// import { toast } from 'react-toastify';

// const useStyles = makeStyles((theme) => ({
//     evenRow: {
//         backgroundColor: '#D0EFFF',
//     },
//     oddRow: {
//         backgroundColor: '#FFFFFF',
//     },
//     PayInText: {
//         color: 'green',
//     },
// }));

// const NewPayOutReport = () => {

//     const [searchQuery, setSearchQuery] = useState('');
//     const [showSearchInput, setShowSearchInput] = useState(false);
//     // const [payOutData, setTransactionData] = useState(null);

//     const classes = useStyles();
//     const [payOutData, setPayOutData] = useState([]);
//     const [   loading, setLoading] = useState(true);

//     useEffect(() => {
//       const fetchPayOutData = async () => {
//         const jwtToken = localStorage.getItem('jwtToken');
//         try {
//           const response = await axios.get('https://api.ipaisa.co.in/myapp/auth/pOuttTxnDetails', {
//             headers: { Authorization: `Bearer ${jwtToken}` }
//           });
//           setPayOutData(response.data.body || []);
//           setLoading(false);
//         } catch (error) {
//           console.error('Error fetching PayOut data:', error);
//           setLoading(false);
//         }
//       };

//       fetchPayOutData();
//     }, []);

//     const formatDate = (dateTimeString) => {
//       if (!dateTimeString) return 'Invalid Date'; // Handle undefined or null dateTimeString

//       const [datePart, timePart] = dateTimeString.split('T');
//       const [year, month, day] = datePart.split('-');

//       // Check if parts are valid and formatted properly
//       if (!datePart || !timePart || !year || !month || !day) return 'Invalid Date';

//       const formattedDate = `${day}-${month}-${year}`;
//       return `${formattedDate} ${timePart}`;
//     };

//     const generatePDF = () => {
//       if (!payOutData || payOutData.length === 0) {
//         alert('No transaction data available to generate PDF.');
//         return;
//       }

//       const doc = new jsPDF();
//       const logo = new Image();
//       logo.src = iPaisaLogo;

//       logo.onload = () => {
//         doc.addImage(logo, 'PNG', 10, 10, 30, 30); // Adjust position and size as needed
//         doc.setFont('helvetica', 'bold');
//         doc.setFontSize(16);
//         doc.text('Transaction Statement', 50, 20);
//         doc.setFontSize(12);
//         doc.text('24 Jun, 2024 - 24 Jul, 2024', 50, 30);

//         const tableColumn = ["Date", "Transaction Details", "Type", "Amount"];
//         const tableRows = [];

//         payOutData.forEach(row => {
//           // Destructure row properties based on the response structure
//           const {
//             timestamp, txnReferenceId, txnValue, status, payerAccount, payerName, payeeAccount, payeeName,
//             poolOpeningBal, poolClosingBal
//           } = row;

//           // Check if the properties exist to avoid undefined values
//           const rowData = [
//             timestamp ? new Date(timestamp).toLocaleString() : 'N/A',
//             `Paid to: ${payeeName || 'N/A'}\nTransaction ID: ${txnReferenceId || 'N/A'}\nUTR No.: ${txnReferenceId || 'N/A'}\nPaid by: ${payerName || 'N/A'}\nAccount Number: ${payeeAccount || 'N/A'}\nTransfer Amount: ${txnValue || 'N/A'}\nWallet Opening Balance: ${Number(poolOpeningBal || 0).toFixed(2)}\nWallet Closing Balance: ${Number(poolClosingBal || 0).toFixed(2)}\nStatus: ${status || 'N/A'}`,
//             'Pay-Out',
//             txnValue ? `${txnValue}` : 'N/A'
//           ];

//           tableRows.push(rowData);
//         });

//         doc.autoTable({
//           head: [tableColumn],
//           body: tableRows,
//           startY: 50,
//           styles: {
//             fontSize: 10,
//             cellPadding: 3,
//             overflow: 'linebreak',
//           },
//           headStyles: {
//             fillColor: [22, 160, 133],
//             textColor: [255, 255, 255],
//             fontSize: 12,
//           },
//         });

//         doc.save('Transaction_Statement.pdf');
//       };
//     };



//     if (loading) {
//       return (
//         <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
//           <CircularProgress />
//         </Grid>
//       );
//     }

//   return (
//     <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
//         <Grid style={{display:'flex', alignItems:'center', gap:'1%', justifyContent:'start'}}>
//             <Typography color='textSecondary' style={{fontFamily:'sans-serif', fontSize:'14px'}}>Report</Typography>
//             <PartnerArrowIcon/>
//             <Typography color='textSecondary' style={{fontFamily:'sans-serif', fontSize:'14px'}}>Pay Out Report</Typography>
//         </Grid>
//         <Grid>
//             <Grid style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'1%'}}>
//                 <Grid>
//                 <Typography style={{fontWeight:'bold', fontFamily:'sans-serif', fontSize:'25px', color:'#343C6A'}}>Pay Out Report</Typography>
//                 </Grid>

//                 <Grid style={{display:'flex', gap:'3%'}}>
//                 <Grid style={{
//                     display:'flex',
//                     backgroundColor: 'rgba(255, 255, 255, 0.1)',
//                     maxWidth: '60%',
//                     borderRadius: 10,
//                     border: '1px solid rgba(0, 0, 0, 0.3)',
//                     }}>

//                 <IconButton>
//                     <SearchIcon style={{ color: 'rgba(0, 0, 0, 0.5)' }} />
//                 </IconButton>
//                 <InputBase
//                     style={{ color: 'rgba(0, 0, 0, 3)' }}
//                     placeholder="Search"
//                     sx={{ width: '100%'}}
//                     onChange={(e) => setSearchQuery(e.target.value)}
//                     onClick={() => setShowSearchInput(!showSearchInput)}
//                 />
//                 </Grid>
//                     <Button style={{textTransform:'none', backgroundColor:'#027BFE', color:'#FFFFFF', borderRadius:'10px', display:'flex', alignItems:'center', justifyContent:'center', paddingRight:'5%', fontFamily:'sans-serif', fontSize:'14px', width:'80%', gap:'5%'}}
//                     onClick={generatePDF}

//                     >
//                     <DownloadIcon/>
//                     Download PDF File</Button>
//                 </Grid>    
//             </Grid>
//         </Grid>

//         <Grid style={{ marginTop: '3%' }}>
//             <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', borderRadius:'12px 12px 0px 0px'  }}>
//             <Table>
//                 <TableHead style={{ backgroundColor: '#027BFE', color: 'white' }}>
//                 <TableRow>
//                     <TableCell style={{ color: 'white' }} align="center">Sr. No.</TableCell>
//                     <TableCell style={{ color: 'white' }} align="center">Type</TableCell>
//                     <TableCell style={{ color: 'white' }} align="center">Txn ID</TableCell>
//                     <TableCell style={{ color: 'white' }} align="center">Utr No.</TableCell>
//                     <TableCell style={{ color: 'white' }} align="center">Account No.</TableCell>
//                     <TableCell style={{ color: 'white' }} align="center">Account Name</TableCell>
//                     <TableCell style={{ color: 'white' }} align="center">Status</TableCell>
//                     <TableCell style={{ color: 'white' }} align="center">Date</TableCell>
//                     <TableCell style={{ color: 'white' }} align="center">Amount</TableCell>
//                     <TableCell style={{ color: 'white' }} align="center">Surcharge</TableCell>
//                     <TableCell style={{ color: 'white' }} align="center">Total Amount</TableCell>
//                 </TableRow>
//                 </TableHead>
//                 <TableBody>
//                 {payOutData && payOutData.length > 0 ? (
//                     payOutData.map((txn, index) => (
//                     <TableRow key={txn.id} className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
//                         <TableCell align="center">{index + 1}</TableCell>
//                         <TableCell align="center" style={{color:'red', fontWeight:'bold', fontSize:'15px', alignItems:'center', display:'flex'}}>Pay-Out<CallMadeIcon/></TableCell>
//                         <TableCell align="center">{txn.txnReferenceId}</TableCell>
//                         <TableCell align="center">{txn.orderid}</TableCell>
//                         <TableCell align="center">{txn.poolAccount}</TableCell>
//                         <TableCell align="center">{txn.payeeName}</TableCell>
//                         <TableCell align="center">{txn.status}</TableCell>
//                         <TableCell align="center">{formatDate(txn.timestamp)}</TableCell>
//                         <TableCell align="center">{txn.txnValue}</TableCell>
//                         <TableCell align="center">{txn.poolAmount}</TableCell>
//                         <TableCell align="center">{(parseFloat(txn.txnValue) + parseFloat(txn.poolAmount)).toFixed(2)}</TableCell>
//                     </TableRow>
//                     ))
//                 ) : (
//                     <TableRow>
//                     <TableCell colSpan={12} align="center">
//                         No transactions found.
//                     </TableCell>
//                     </TableRow>
//                 )}
//                 </TableBody>
//             </Table>
//             </TableContainer>
//         </Grid>
//     </Container>
//   )
// }

// export default NewPayOutReport


import React, { useState, useEffect } from 'react';
import SearchIcon from "@mui/icons-material/Search";
import { Button, Container, Grid, IconButton, InputBase, Typography, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { DownloadIcon, PartnerArrowIcon } from '../../res/NewWebIcons';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import iPaisaLogo from '../Assets/iPaisaLogo/iPaisaLogo.jpg'; // Adjust this path if needed

const useStyles = makeStyles((theme) => ({
    evenRow: {
        backgroundColor: '#D0EFFF',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    },
    PayInText: {
        color: 'green',
    },
}));

const NewPayOutReport = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [showSearchInput, setShowSearchInput] = useState(false);
    const classes = useStyles();
    const [payOutData, setPayOutData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPayOutData = async () => {
            const jwtToken = localStorage.getItem('jwtToken');
            try {
                const response = await axios.get('https://api.ipaisa.co.in/myapp/auth/pOuttTxnDetails', {
                    headers: { Authorization: `Bearer ${jwtToken}` }
                });
                setPayOutData(response.data.body || []);
            } catch (error) {
                console.error('Error fetching PayOut data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchPayOutData();
    }, []);

    const formatDate = (dateTimeString) => {
        if (!dateTimeString) return 'Invalid Date';
        const [datePart, timePart] = dateTimeString.split('T');
        const [year, month, day] = datePart.split('-');
        if (!datePart || !timePart || !year || !month || !day) return 'Invalid Date';
        return `${day}-${month}-${year} ${timePart}`;
    };

    const downloadCSV = () => {
        const csvRows = [];
        const headers = ["Sr. No.", "Type", "Txn ID", "Utr No.", "Account No.", "Account Name", "Status", "Date", "Amount", "Surcharge", "Total Amount"];
        csvRows.push(headers.join(','));

        if (payOutData.length > 0) {
            payOutData.forEach((row, index) => {
                const {
                    timestamp, txnReferenceId, txnValue, status, payerAccount, payerName, payeeAccount, payeeName,
                    poolOpeningBal, poolClosingBal, surcharge
                } = row;

                const rowData = [
                    index + 1, // Sr. No.
                    'Pay-Out', // Type
                    Number(txnReferenceId) || 'N/A', // Txn ID
                    Number(txnReferenceId) || 'N/A', // Utr No.
                    payeeAccount || 'N/A', // Account No.
                    payeeName || 'N/A', // Account Name
                    status || 'N/A', // Status
                    timestamp ? new Date(timestamp).toLocaleString() : 'N/A', // Date
                    poolOpeningBal || 'N/A',
                    poolClosingBal || 'N/A',
                    txnValue ? `${txnValue}` : 'N/A', // Amount
                    surcharge ? `${surcharge}` : 'N/A', // Surcharge
                    surcharge ? `${txnValue + surcharge}` : `${txnValue}` // Total Amount
                ];

                csvRows.push(rowData.map(cell => `"${cell}"`).join(','));
            });
        }

        const csvContent = `data:text/csv;charset=utf-8,${csvRows.join('\n')}`;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Transaction_Statement.csv");
        document.body.appendChild(link);
        link.click();
    };


    if (loading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
            <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Report</Typography>
                <PartnerArrowIcon />
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Pay Out Report</Typography>
            </Grid>
            <Grid>
                <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1%' }}>
                    <Grid>
                        <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '25px', color: '#343C6A' }}>Pay Out Report</Typography>
                    </Grid>
                    <Grid style={{ display: 'flex', gap: '3%' }}>
                        <Grid style={{
                            display: 'flex',
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            maxWidth: '60%',
                            borderRadius: 10,
                            border: '1px solid rgba(0, 0, 0, 0.3)',
                        }}>
                            <IconButton>
                                <SearchIcon style={{ color: 'rgba(0, 0, 0, 0.5)' }} />
                            </IconButton>
                            <InputBase
                                style={{ color: 'rgba(0, 0, 0, 3)' }}
                                placeholder="Search"
                                sx={{ width: '100%' }}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                onClick={() => setShowSearchInput(!showSearchInput)}
                            />
                        </Grid>
                        <Button
                            style={{ textTransform: 'none', backgroundColor: '#027BFE', color: '#FFFFFF', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingRight: '5%', fontFamily: 'sans-serif', fontSize: '14px', width: '80%', gap: '5%' }}
                            onClick={downloadCSV}
                        >
                            <DownloadIcon />
                            Download CSV File
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid style={{ marginTop: '3%' }}>
                <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', borderRadius: '12px 12px 0px 0px' }}>
                    <Table>
                        <TableHead style={{ backgroundColor: '#027BFE', color: 'white' }}>
                            <TableRow>
                                <TableCell style={{ color: 'white' }} align="center">Sr. No.</TableCell>
                                <TableCell style={{ color: 'white' }} align="center">Type</TableCell>
                                <TableCell style={{ color: 'white' }} align="center">Txn ID</TableCell>
                                <TableCell style={{ color: 'white' }} align="center">Utr No.</TableCell>
                                <TableCell style={{ color: 'white' }} align="center">Account No.</TableCell>
                                <TableCell style={{ color: 'white' }} align="center">Account Name</TableCell>
                                <TableCell style={{ color: 'white' }} align="center">Status</TableCell>
                                <TableCell style={{ color: 'white' }} align="center">Date</TableCell>
                                <TableCell style={{ color: 'white' }} align="center">Amount</TableCell>
                                <TableCell style={{ color: 'white' }} align="center">Surcharge</TableCell>
                                <TableCell style={{ color: 'white' }} align="center">Total Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {payOutData && payOutData.length > 0 ? (
                                payOutData.map((txn, index) => (
                                    <TableRow key={txn.id} className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                        <TableCell align="center">{index + 1}</TableCell>
                                        <TableCell align="center" style={{ color: 'red', fontWeight: 'bold', fontSize: '15px', alignItems: 'center', display: 'flex' }}>Pay-Out<CallMadeIcon /></TableCell>
                                        <TableCell align="center">{txn.txnReferenceId}</TableCell>
                                        <TableCell align="center">{txn.orderid}</TableCell>
                                        <TableCell align="center">{txn.payeeAccount}</TableCell>
                                        <TableCell align="center">{txn.payeeName}</TableCell>
                                        <TableCell align="center">{txn.status}</TableCell>
                                        <TableCell align="center">{formatDate(txn.timestamp)}</TableCell>
                                        <TableCell align="center">{txn.txnValue}</TableCell>
                                        <TableCell align="center">0</TableCell>
                                        <TableCell align="center">{txn.txnValue}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={11} align="center">No Data Available</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Container>
    );
};

export default NewPayOutReport;
