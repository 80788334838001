import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, Container, Grid, Paper, TextField, Typography } from '@mui/material'
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import WalletBalanceComponent from "./WalletBalanceComponent";
import { PartnerArrowIcon } from "../../res/NewWebIcons";
import MoneyAddedSucc from "./MoneyAddedSucc";
import MoneyFailedtoAdd from "./MoneyFailedtoAdd";

const NewAddMoney = () => {

  const [walletBalance, setWalletBalance] = useState(0);
  const [moneyaddedpop, setMoneyaddedpop] = useState(false);
  const [moneyfailedtoadd, setMoneyfailedtoadd] = useState(false);

  const handleClose = () => {
    setMoneyaddedpop(false);
    setMoneyfailedtoadd(false);
  };

  const fetchWalletBalance = async () => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/auth/getwalletbalance",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWalletBalance(response.data.walletBalance);
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
    }
  };

  useEffect(() => {
    fetchWalletBalance();
  }, []);

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError("Amount must be a number")
      .positive("Amount must be positive")
      .required("Amount is required"),
    remark: Yup.string().required("Remark is required"),
  });

  const formik = useFormik({
    initialValues: {
      amount: "",
      remark: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Directly initiate payment
      initiatePayment(values);
    },
  });

  const initiatePayment = async (values) => {
    try {
      const paymentData = {
        txnid: "iPaisa" + new Date().getTime(),
        amount: values.amount,
        productinfo: "Add Money",
        firstname: "Edsom",
        phone: "8378054080",
        email: "edsomfintech@gmail.com",
        surl: "https://ipaisa.co.in/signin",
        furl: "https://ipaisa.co.in/signin",
      };
  
      const token = localStorage.getItem("jwtToken");
      const response = await axios.post(
        "https://api.ipaisa.co.in/myapp/auth/easebuzz/initiate-payment",
        paymentData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      const responseBody = response.data;
  
      if (responseBody.status === 1) {
        const accessKey = responseBody.data;
        const paymentUrl = `https://pay.easebuzz.in/pay/${accessKey}`;
  
        const newWindow = window.open(paymentUrl, "_blank");
  
        if (newWindow) {
          const checkWindowUrl = setInterval(() => {
            try {
              const currentUrl = newWindow.location.href;
              const successUrl = "https://ipaisa.co.in/signin";
              const failureUrl = "https://ipaisa.co.in/signin";
  
              if (currentUrl === successUrl || currentUrl === failureUrl) {
                clearInterval(checkWindowUrl);
                newWindow.close();
                setTimeout(() => {
                  handlePaymentStatus(paymentData.txnid);
                }, 1000);
              }
            } catch (e) {
              // Catch and ignore cross-origin errors
            }
          }, 1000);
  
          // Set a timeout to close the window after 5 minutes (300,000 milliseconds)
          setTimeout(() => {
            if (!newWindow.closed) {
              newWindow.close();
              console.log("Payment window closed after 5 minutes");
            }
          }, 300000);
        }
      } else {
        console.error("Access key not received from backend");
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  };
  

  const handlePaymentStatus = async (txnid) => {
    try {
      const token = localStorage.getItem("jwtToken");

      const response = await axios.post(
        "https://api.ipaisa.co.in/myapp/auth/transaction-api-v2",
        { txnid },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      handlePaymentSuccess(response.data);

      await fetchWalletBalance();

      if (response.data) {
        toast.success("Funds Added Successfully");
        setMoneyaddedpop(true);
      }
    } catch (error) {
      console.error("Error verifying payment:", error);
      handlePaymentSuccess({ status: 0, message: "Error verifying payment" });
      setMoneyfailedtoadd(true);
    }
  };

  const handlePaymentSuccess = (response) => {
    console.log("Payment success response:", response);
    if (response.msg?.status === "userCancelled") {
      console.log("User cancelled the payment");
    }
    if (response.status === 1) {
      toast.success("Payment successful");
      setMoneyaddedpop(true);
    } else {
      // toast.error("Payment failed: " + response.message);
    }
  };
  
    const handleAmountChange = (e) => {
        const { name, value } = e.target;
      
        let validValue = value.replace(/[^0-9]/g, '');
      
        if (validValue.startsWith('0') && validValue.length > 1) {
          validValue = validValue.replace(/^0+/, '');
        }
      
        if (validValue === '0') {
          validValue = '';
        }
      
        formik.setFieldValue(name, validValue);
      };

      const lineHeight = '50px';

  return (
    <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
       <Grid>
            <Grid style={{display:'flex', alignItems:'center', gap:'1%', justifyContent:'start'}}>
                <Typography color='textSecondary' style={{fontFamily:'sans-serif', fontSize:'13px'}}>Balance</Typography>
                <PartnerArrowIcon/>
                <Typography color='textSecondary' style={{fontFamily:'sans-serif', fontSize:'13px'}}>Add Money</Typography>
            </Grid>

            <Grid style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'1%'}}>
                <Grid>
                <Typography style={{fontWeight:'bold', fontFamily:'sans-serif', fontSize:'19px', color:'#343C6A'}}>Add Money</Typography>
                </Grid> 
            </Grid>
        </Grid>

       <Grid container spacing={2} style={{ marginTop: '3%' }}>
        {/* Wallet Balance Card */}
        <WalletBalanceComponent walletBalance={walletBalance}/>

       <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
        <Paper style={{ padding: '2rem', borderRadius: '8px' }}>
        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h7">Amount</Typography>
                    <TextField
                      name="amount"
                      type="text"
                      value={formik.values.amount}
                      onChange={handleAmountChange}
                      fullWidth
                      required
                      InputLabelProps={{
                        style: { height: "2.5em" },
                      }}
                      error={formik.touched.amount && Boolean(formik.errors.amount)}
                      helperText={formik.touched.amount && formik.errors.amount}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h7">Remark</Typography>
                    <TextField
                      name="remark"
                      value={formik.values.remark}
                      onChange={formik.handleChange}
                      fullWidth
                      required
                      multiline
                      rows={4}
                      InputLabelProps={{
                        style: { height: "3.5em" },
                      }}
                      error={formik.touched.remark && Boolean(formik.errors.remark)}
                      helperText={formik.touched.remark && formik.errors.remark}
                    />
                  </Grid>
                  <Grid item xs={12}>
                  <Box display="flex" justifyContent="end" gap={1} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                  <Button
                      sx={{
                        border: '1.5px solid #FF0000',
                        height: lineHeight,
                        fontSize: '16px',
                        borderRadius: '8px',
                        color: '#FF0000',
                        textTransform: 'none',
                        width: { xs: '100%', md: '18%' },
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                     sx={{
                        height: lineHeight,
                        fontSize: '16px',
                        textTransform:'none',
                        width: { xs: '100%', md: '18%' },
                        borderRadius: '8px',
                        backgroundColor: "#027BFE",
                        color: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: "#027BFE",
                            color: '#FFFFFF',
                        },
                      }}
                      type="submit"
                      onClick={initiatePayment}
                    >
                      Add Money
                    </Button>
                    </Box>
                  </Grid>
                  
                </Grid>
              </form>
            </Paper>
        </Grid>
    </Grid>

    {moneyaddedpop && <MoneyAddedSucc handleClose={handleClose} />}
    {moneyfailedtoadd && <MoneyFailedtoAdd handleClose={handleClose} />}
</Container>
 )
}

export default NewAddMoney
