import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import PersonIcon from "@mui/icons-material/Person";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import ReportIcon from "@mui/icons-material/Report";
import SettingsIcon from "@mui/icons-material/Settings";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import CheckIcon from "@mui/icons-material/Check";
import logo from "../Assets/login/iPaisaLogo.jpg";
import DownloadIcon1 from '@mui/icons-material/Download';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import {
  Avatar,
  CardMedia,
  Collapse,
  Grid,
  InputBase,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import partnerIcon from "../Assets/Dashboard/groups_24dp_FILL1_wght400_GRAD0_opsz24 1.svg";
import GroupsIcon from "@mui/icons-material/Groups";
import iPaisaLogo from "../Assets/iPaisaLogo/iPaisaLogo.jpg";
import {
  Bank_Icon,
  Compensation_Icon,
  CreateTicket_Icon,
  Dashboard_Icon,
  Logout_Icon,
  Report_Icon,
  Services_Icon,
  Setting_Icon,
  Support_Icon,
  iPaisa_Icon,
} from "../../res/icons";

import SearchIcon from "@mui/icons-material/Search";
import { Notifications } from "@mui/icons-material";
// import Login from '../Login/Login';
import Login from "./../Login/Login";
import { fetchPartners } from '../../redux/actions/partnerActions';
import { useDispatch } from "react-redux";
import { BalanceBlackIcon, CompensationBlackIcon, DashboardBlackIcon, LogoutBlackIcon, PartnerBlackIcon, ReportBlackIcon, ServiceBlack, ServiceBlackIcon, SettingsBlackIcon, SupportBlackIcon, TicketBlackIcon } from "../../res/retailer icons";
import { DownloadIcon } from "../../res/NewWebIcons";
import { color } from "framer-motion";

const colors = {
  CHANNELPARTNER: '#092E1F',
  AREADISTRIBUTOR: '#FF7B31',
  MASTERDISTRIBUTOR: '#B25AEF',
  SUPERDISTRIBUTOR: '#4B0DE1',
  RETAILER: '#00DDE5',
  ADMIN: '#027BFE',
  USER: '#027BFE'
};

const getColor = (userType) => colors[userType] || colors.ADMIN;

const drawerWidth = 260;
const fixedWidth = "260px"; // Set a fixed width for ListItemButton


export default function MiniDrawer() {

  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [partnerOpen, setPartnerOpen] = React.useState(false);
  const [reportOpen, setReportOpen] = React.useState(false);
  const [balanceOpen, setBalanceOpen] = React.useState(false);
  const [supportOpen, setSupportOpen] = React.useState(false);
  const [settingOpen, setSettingOpen] = React.useState(false);
  const [servicesOpen, setServicesOpen] = React.useState(false);

  const [compensationOpen, setCompensationOpen] = React.useState(false);
  const [commissionOpen, setCommissionOpen] = React.useState(false);
  const [surchargeOpen, setSurchargeOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");

  const userType = localStorage.getItem("uType");
  const username = localStorage.getItem("username");

  const appBarColor = getColor(userType);
  const drawerColor = getColor(userType);

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: "80%",
    padding: "70px 0px 100px 0px",
    backgroundColor: drawerColor,
    overflowX: "hidden",
    overflowY: "auto", // Enable vertical scrolling
  });


  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: "0px",
    [theme.breakpoints.up("sm")]: {
      width: "0px",
    },
  });

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",

    padding: theme.spacing(0, 1),

    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,

    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const BackdropFilterContainer = styled(Box)({
    backdropFilter: "blur(1%)",
  });



  const navigate = useNavigate();
  const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    const userDetail = localStorage.getItem("userDetail");
    const userType = localStorage.getItem("uType");
    console.log('Here', userDetail)
    console.log('userType', userType)

  }, [])


  const partnerPaths = [
    { id: 1, path: "/cplist", title: "Channel Partner", icon: <GroupsIcon /> },
    {
      id: 2,
      path: "/sdlist",
      title: "Super Distributor",
      icon: <GroupsIcon />,
    },
    {
      id: 3,
      path: "/mdlist",
      title: "Master Distributor",
      icon: <GroupsIcon />,
    },
    { id: 4, path: "/adlist", title: "Area Distributor", icon: <GroupsIcon /> },
    { id: 5, path: "/retailer", title: "Retailer", icon: <GroupsIcon /> },
    {
      id: 6,
      path: "/changeparent",
      title: "Change Parent",
      icon: <GroupsIcon />,
    },
    {
      id: 7,
      path: "/kycverfication",
      title: "KYC Verification",
      icon: <GroupsIcon />,
    },
  ];

  const servicesPaths = [
    {
      id: 41,
      path: "/paybills",
      title: "Pay Bills",
      icon: <GroupsIcon />,
    },
    {
      id: 42,
      path: "/bookandbuy",
      title: "Book and Buy",
      icon: <GroupsIcon />,
    },
  ];

  const commonPaths = [
    { id: 17, path: "/fundtransfer", title: "Fund Transfer", icon: <GroupsIcon />},
    { id: 19, path: "/addmoney", title: "Add Money", icon: <GroupsIcon /> },
    { id: 20, path: "/addBeneficiary", title: "Add Beneficiary", icon: <GroupsIcon /> },
    { id: 29, path: "/payout", title: "Pay Out", icon: <GroupsIcon /> },
    // { id: 21, path: "/bankcharges", title: "Bank Charges", icon: <GroupsIcon /> },
  ];
  if (userType === 'ADMIN') {
    commonPaths.push({
      id: 18,
      path: "/reversemoney",
      title: "Reverse Money",
      icon: <GroupsIcon />,
    });
    commonPaths.push({
      id: 18,
      path: "/bankcharges",
      title: "Bank Charges",
      icon: <GroupsIcon />,
    });
  }

  const isADMIN = userType === "ADMIN";



  const commissionpaths = [
    {
      id: 15,
      path: "/fixcommission",
      title: "Fix Commission",
      icon: <GroupsIcon />,
    },
    {
      id: 16,
      path: "/variablecommission",
      title: "Variable Commission",
      icon: <GroupsIcon />,
    },
  ];


  const surchargepaths = [
    { id: 21, path: "/surcharge", title: "Surcharge", icon: <GroupsIcon /> },
  ];

  const reportsPaths = [
    // { id: 68, path: "/payinndout", title: "PayIn and Payout", icon: <GroupsIcon /> },
    { id: 68, path: "/payinreport", title: "PayIn", icon: <GroupsIcon /> },
    { id: 68, path: "/payoutreport", title: "Payout", icon: <GroupsIcon /> },

    { id: 8, path: "/FundTransferReports", title: "FTR", icon: <GroupsIcon /> },
    {
      id: 9,
      path: "/TransactionalReport",
      title: "Transactional Report",
      icon: <GroupsIcon />,
    },
    {
      id: 10,
      path: "/TransactionalHistory",
      title: "Transactional History",
      icon: <GroupsIcon />,
    },
    {
      id: 11,
      path: "/commissionreport",
      title: "Commission Report",
      icon: <GroupsIcon />,
    },
    {
      id: 12,
      path: "/surchargereport",
      title: "Surcharge Report",
      icon: <GroupsIcon />,
    },
    {
      id: 13,
      path: "/balReqHisReport",
      title: "Balance Request History",
      icon: <GroupsIcon />,
    },
    {
      id: 14,
      path: "/userCashbackReport",
      title: "User Cashback Report",
      icon: <GroupsIcon />,
    },
  ];

  const settingsPaths = [
    {
      id: 36,
      path: "/generalsetting",
      title: "General Setting",
      icon: <GroupsIcon />,
    },
  ];
  if (isADMIN) {
    settingsPaths.push({
      id: 20,
      path: "/blogsupdater",
      title: "Blogs Updater",
      icon: <GroupsIcon />,
    });
    settingsPaths.push({
      id: 27,
      path: "/sendsms",
      title: "Send SMS",
      icon: <GroupsIcon />,
    });
  }
  const handlePartnerClick = async () => {
    setPartnerOpen(!partnerOpen); // Toggle the partner list state

    // Check if the partner list is being opened
    if (!partnerOpen) {
      dispatch(fetchPartners());
    }
  };

  const supportPaths = [
    {
      id: 20,
      path: "/fundtransferticket",
      title: "Fund Transfer Ticket",
      icon: <GroupsIcon />,
    },
    {
      id: 21,
      path: "/usercreationticket",
      title: "User Creation Ticket",
      icon: <GroupsIcon />,
    },
    {
      id: 22,
      path: "/tickethandling",
      title: "Ticket Handling",
      icon: <GroupsIcon />,
    },
    {
      id: 28,
      path: "/ongoingticket",
      title: "Ongoing Ticket",
      icon: <GroupsIcon />,
    },
    {
      id: 29,
      path: "/pendingticket",
      title: "Pending Ticket",
      icon: <GroupsIcon />,
    },
    {
      id: 30,
      path: "/closeticket",
      title: "Closed Ticket",
      icon: <GroupsIcon />,
    },
    {
      id: 30,
      path: "/changeparentsupport",
      title: "Change Parent",
      icon: <GroupsIcon />,
    },
  ];
  const handleDrawerClose = () => {
    setOpen(false);
    if (partnerOpen) {
      setPartnerOpen(false); // Close the partner dropdown if it's open
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    // localStorage.removeItem("jwtToken");
    // Navigate to the login page
    // navigate("/signin");
    setAnchorEl(null);
  };

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSupportClick = async () => {
    setSupportOpen(!supportOpen); // Toggle the support list state

    // Check if the support list is being opened
    if (!supportOpen) {
      try {
        const token = localStorage.getItem("jwtToken");

        // Ensure the token is not null or undefined
        if (!token) {
          throw new Error("No JWT token found in local storage");
        }

        const userType = localStorage.getItem("userType"); // Retrieve the user type
        let endpoint = '';

        if (userType === 'ADMIN') {
          endpoint = 'https://api.ipaisa.co.in/myapp/admin/getAllTickets';
        } else {
          endpoint = 'https://api.ipaisa.co.in/myapp/auth/getbyuser';
        }

        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log("API Response:", response.data);
        // Handle the response data as needed
      } catch (error) {
        console.error("API Error:", error);

        // Check if the error is due to an invalid token
        if (error.response && error.response.status === 401) {
          console.error("Invalid or expired token. Please log in again.");
          // Optionally redirect to login page or show a login prompt
        } else {
          console.error("An error occurred while fetching the tickets.");
        }
      }
    }
  };


  const handleReportClick = () => {
    setReportOpen(!reportOpen); // Toggle the report list state
  };
  const handleBalanceClick = () => {
    setBalanceOpen(!balanceOpen); // Toggle the report list state
  };
  const handleCompensationClick = () => {
    setCompensationOpen(!compensationOpen); // Toggle the compensation list state
  };

  const handleCommissionClick = () => {
    setCommissionOpen(!commissionOpen); // Toggle the commission list state
  };

  const handleSurchargeClick = () => {
    // setSurchargeOpen(!surchargeOpen); // I Comment this handleOpen Button because surcharge page is not ready yet 
  };

  const handlebankchargesclick = () => {
    navigate('/bankcharges');
  };

  // const handleSupportClick = () => {
  //   setSupportOpen(!supportOpen); // Toggle the surcharge list state
  // };
  const handleSettingClick = () => {
    setSettingOpen(!settingOpen); // Toggle the surcharge list state
  };

  const handleOpenChannelPartner = () => {
    navigate("/cplist");
  };
  const handleOpenSuperDistributor = () => {
    navigate("/sdlist");
  };
  const handleOpenMasterDistributor = () => {
    navigate("/mdlist");
  };
  const handleOpenArearDistributor = () => {
    navigate("/adlist");
  };

  const handleOpenRetailer = () => {
    navigate("/retailer");
  };
  const handleOpenChangeParent = () => {
    navigate("/changeparent");
  };
  const handleOpenKycVerification = () => {
    navigate("/kycverification");
  };
  const handleOpenFTR = () => {
    navigate("/FundTransferReports");
  };
  const handleOpenRequestPayment = () => {
    navigate("/requestpaymentissue");
  };

  const handleOpenCreateTicket = () => {
    navigate("/createticket");
  };
  const handleOpenTransactionalReport = () => {
    navigate("/TransactionalReport");
  };

  const handleOpenTransactionalHistoryReport = () => {
    navigate("/TransactionalHistory");
  };
  const handleOpenCommissionReport = () => {
    navigate("/commissionreport");
  };
  const handleOpenSurchargeReport = () => {
    navigate("/surchargereport");
  };
  const handleOpenBalReqHisReport = () => {
    navigate("/balReqHisReport");
  };
  const handleOpenUserCashbackReport = () => {
    navigate("/userCashbackReport");
  };
  const handleOpenFixCommission = () => {
    navigate("/fixCommission");
  };
  const handleOpenVariableCommission = () => {
    navigate("/variablecommission");
  };
  const handleOpenfundtransfer = () => {
    navigate("/fundtransfer");
  };
  const handleOpenReverseMoney = () => {
    navigate("/reversemoney");
  };
  const handleOpenAddMoney = () => {
    navigate("/addmoney");
  };
  const handleOpenAddBeneficary = () => {
    navigate("/addBeneficiary");
  };
  const handleOpenFundTransferTicket = () => {
    navigate("/fundtransferticket");
  };
  const handleOpenUserCreationTicket = () => {
    navigate("/usercreationticket");
  };
  const handleOpenTicketHandling = () => {
    navigate("/tickethandling");
  };
  const handleOpenSendSms = () => {
    navigate("/sendsms");
  };
  const handleOpenGeneralSetting = () => {
    navigate("/generalsetting");
  };
  const handleServicesClick = () => {
    setServicesOpen(!servicesOpen)
  };
  const handleOpenOngoingTicket = () => {
    navigate("/ongoingticket");
  };
  const handleCloseTicket = () => {
    navigate("/closeticket");
  };
  const handlePendingClick = () => {
    navigate("/pendingticket");
  };
  const handleDashboardClick = () => {
    navigate("/dashboard");
  };

  const handleLogout = () => {
    localStorage.removeItem("jwtToken");
    navigate("/signin");
  };

  const handleProfileOpen = () => {
    navigate('/userprofile');
  };


  const handleAddTechSupport = () => {
    navigate("/techsupport");
  };



  const userTypeOptions = {
    CHANNELPARTNER: [
      { label: 'Super Distributor', onClick: handleOpenSuperDistributor },
      { label: 'Master Distributor', onClick: handleOpenMasterDistributor },
      { label: 'Area Distributor', onClick: handleOpenArearDistributor },
      { label: 'Retailer', onClick: handleOpenRetailer },
    ],
    SUPERDISTRIBUTOR: [
      { label: 'Master Distributor', onClick: handleOpenMasterDistributor },
      { label: 'Area Distributor', onClick: handleOpenArearDistributor },
      { label: 'Retailer', onClick: handleOpenRetailer },
    ],
    MASTERDISTRIBUTOR: [
      { label: 'Area Distributor', onClick: handleOpenArearDistributor },
      { label: 'Retailer', onClick: handleOpenRetailer },
    ],
    AREADISTRIBUTOR: [
      { label: 'Retailer', onClick: handleOpenRetailer },
    ],
    ADMIN: [
      { label: 'Channel Partner', onClick: handleOpenChannelPartner },
      { label: 'Super Distributor', onClick: handleOpenSuperDistributor },
      { label: 'Master Distributor', onClick: handleOpenMasterDistributor },
      { label: 'Area Distributor', onClick: handleOpenArearDistributor },
      { label: 'Retailer', onClick: handleOpenRetailer },
      { label: 'Tech Support', onClick: handleAddTechSupport },
    ],
  };
  const renderListItems = (items, handleClick) => {
    return items.map((item, index) => (
      <ListItem key={index} onClick={item.onClick} sx={{ "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.05)" } }}>
        {item.label}
      </ListItem>
    ));
  };


  const allPaths = [
    ...partnerPaths,
    ...servicesPaths,
    ...commonPaths,
    ...commissionpaths,
    ...surchargepaths,
    ...reportsPaths,
    ...settingsPaths,
    ...supportPaths,
  ];
  // const filteredPaths = allPaths.filter((path) =>
  //   path.title.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  const filteredPaths2 = (userType === "ADMIN" || userType === "CHANNELPARTNER" || userType === "SUPERDISTRIBUTOR" || userType === "MASTERDISTRIBUTOR")
  ? commonPaths.filter((item) => 
      item.title === "Fund Transfer" || 
      item.title === "Add Beneficiary" || 
      item.title === "Reverse Money" || 
      item.title === "Bank Charges"
    )
  : userType === "RETAILER"
  ? commonPaths.filter((item) => item.title !== "Fund Transfer")
  : commonPaths;

  const filteredPaths = allPaths.filter((path) =>
    path.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const [activeComponent, setActiveComponent] = React.useState(null);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleListItemClick = (path) => {
    navigate(path);
  };
  const handleMenuItemClick = (component) => {
    setActiveComponent(component);
    handleMenuClose();
  };

  return (
    <BackdropFilterContainer sx={{ display: "flex" }}>
      {/* <CssBaseline /> */}
      <AppBar position="fixed" open={open} elevation={0} sx={{ boxShadow: 'none' }}>
        <Toolbar sx={{ backgroundColor: drawerColor, height: 90, display: 'flex', justifyContent: 'space-between', backgroundColor: appBarColor }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%', flexWrap: 'wrap' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexGrow: 1,
                maxWidth: isLargeScreen ? '700px' : '100%',
                height: '48px',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: 30,
                border: userType === 'RETAILER' ? 'black' : '1px solid rgba(255, 255, 255, 0.5)',
                marginLeft: '25px',
                marginTop: isSmallScreen ? '8px' : '0',
              }}
            >
              <IconButton sx={{ padding: '10px' }}>
                <SearchIcon style={{ color: userType === 'RETAILER' ? 'black' : 'rgba(255, 255, 255, 1.5)' }} />
              </IconButton>
              <InputBase
                style={{ color: userType === 'RETAILER' ? 'black' : 'rgba(255, 255, 255, 1)' }}
                placeholder="Type here to Search..."
                sx={{ width: '100%', paddingLeft: '8px' }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
            {isLargeScreen && (
              <List>
                {searchQuery &&
                  filteredPaths.map((item) => (
                    <ListItem key={item.id} disablePadding>
                      <ListItemButton onClick={() => handleListItemClick(item.path)}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.title} />
                      </ListItemButton>
                    </ListItem>
                  ))}

              </List>
            )}
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton aria-label="open notifications" edge="end" sx={{ marginLeft: '2%', color: userType === 'RETAILER' ? 'black' : 'white' }}>
              <Notifications />
            </IconButton>
            <div
              style={{
                marginLeft: '16px',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',
                color: 'black',
              }}
            >
              <Typography
                variant="body1"
                style={{ fontWeight: 'bold', fontSize: '20px', color: userType === 'RETAILER' ? 'black' : 'white' }}
              >
                {username}
              </Typography>
              <Typography variant="caption" style={{ color: userType === 'RETAILER' ? 'black' : 'white' }}>
                {userType}
              </Typography>
            </div>

            <IconButton
              color="inherit"
              aria-label="open profile menu"
              onClick={handleProfileMenuOpen}
              sx={{ marginLeft: '16px' }}
            >
              <Avatar />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                sx: { width: isSmallScreen ? '50%' : '15%', textAlign: 'center', height: '32%', marginLeft: '83%', marginTop: '4.5%', backgroundColor:'#dfe9f2' },
              }}
            >
              <MenuItem onClick={handleProfileOpen}>Profile</MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('changeMpin')}>Change MPIN</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
              <Divider/>
                <Grid>
                  <img src={logo} alt="iPaisa Logo" style={{height: 88, width: 88, border: userType === "RETAILER" ? '3px solid #00178D' : 'none', backgroundColor: 'transparent', borderRadius: '50%'}} />
                </Grid>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>


      <Drawer variant="permanent" open={open} style={{ color: "#CB0C9F" }}>
        <DrawerHeader
          style={{
            backgroundColor: drawerColor,
            justifyContent: "center",
            alignItems: "center",
            marginTop: "-18%",
            gap: 5,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'transparent',

            }}
          >
            <CardMedia
              component="img"
              image={logo}
              alt="iPaisa Logo"
              style={{ backgroundColor: 'transparent', borderRadius: '50%' }}
              sx={{
                height: 90,
                width: 90,
                mt: { xs: 1, md: 1 },
                marginRight: { xs: 0, md: 1 },
                marginLeft: { xs: 0, md: 1 },
                marginBottom: { xs: 1, md: 0 },
                border: userType === "RETAILER" ? '3px solid #00178D' : 'none', // Conditional border
              }}
            />
          </Box>
          <Typography
            style={{
              fontFamily: "sans-serif",
              fontSize: "23px",
              fontWeight: 'bold',
              color: userType === "RETAILER" ? '#00178D' : "white",
            }}
          >
            iPaisa
          </Typography>
        </DrawerHeader>
        <List
          sx={{ backgroundColor: drawerColor, color: "white", marginTop: "13%" }}
        >
          <ListItem
            disablePadding
            sx={{ display: "block", marginTop: "5%" }}
            style={{ backgroundColor: drawerColor, color: userType === "RETAILER" ? "black" : "white" }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                width: fixedWidth, // Apply fixed width
              }}
              onClick={handleDashboardClick}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: userType === "RETAILER" ? "black" : "white"
                }}
              >
                {userType === 'RETAILER' ? <DashboardBlackIcon /> : <Dashboard_Icon />}
              </ListItemIcon>
              <ListItemText
                primary="Dashboard"
                sx={{ opacity: open ? 1 : 0, color: userType === "RETAILER" ? "black" : "white" }}

                onClick={handleDashboardClick}
              />
            </ListItemButton>
          </ListItem>

          {userType !== "RETAILER" && (
            <>
              <ListItem disablePadding sx={{ display: "block", marginTop: "5%" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    width: '100%',
                  }}
                  onClick={() => setPartnerOpen(!partnerOpen)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: userType === "RETAILER" ? "black" : "white"
                    }}
                  >
                    {userType === 'RETAILER' ? <PartnerBlackIcon /> : <Diversity3Icon />}

                  </ListItemIcon>
                  <ListItemText primary="Partner" sx={{ opacity: open ? 1 : 0, color: userType === "RETAILER" ? "black" : "white" }} />
                  {partnerOpen && partnerOpen ? (
                    <ExpandLessIcon />
                  ) : open ? (
                    <ExpandMoreIcon />
                  ) : null}
                </ListItemButton>
              </ListItem>

              {partnerOpen && (
                <List sx={{ marginLeft: "15%", cursor: "pointer", marginTop: "5%" }}>
                  {userTypeOptions[userType] && renderListItems(userTypeOptions[userType], handlePartnerClick)}
                </List>
              )}
            </>
          )}

          {userType === "RETAILER" && ( // Conditionally render if user type is retailer
            <ListItem
              disablePadding
              sx={{
                display: "block",
                backgroundColor: drawerColor,
                color: userType === "RETAILER" ? "black" : "white",
                marginTop: "5%",
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  color: userType === "RETAILER" ? "black" : "white",
                }}
                onClick={handleServicesClick}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: userType === "RETAILER" ? "black" : "white",
                  }}
                >
                  {userType === 'RETAILER' ? <ServiceBlackIcon /> : <Services_Icon />}

                </ListItemIcon>
                <ListItemText primary="Services" sx={{ opacity: open ? 1 : 0, color: userType === "RETAILER" ? "black" : "white", }} />
                {open && servicesOpen ? (
                  <ExpandLessIcon />
                ) : open ? (
                  <ExpandMoreIcon />
                ) : null}
              </ListItemButton>
              <Collapse in={servicesOpen} timeout="auto" unmountOnExit>
                <List sx={{ marginLeft: "7%" }}>
                  {servicesPaths.map((item) => (
                    <ListItem
                      button
                      key={item.id}
                      onClick={() => navigate(item.path)}
                      sx={{ pl: 4, color: userType === "RETAILER" ? "black" : "white", }}
                    >
                      {/* <ListItemIcon>{item.icon}</ListItemIcon> */}
                      <ListItemText primary={item.title} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </ListItem>
          )}
          <ListItem
            disablePadding
            sx={{
              display: "block",
              marginTop: "5%",
              backgroundColor: drawerColor,
              color: userType === "RETAILER" ? "black" : "white",
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                width: fixedWidth, // Apply fixed width
                color: userType === "RETAILER" ? "black" : "white",
              }}
              onClick={handleBalanceClick}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: userType === "RETAILER" ? "black" : "white",
                }}
              >
                {userType === 'RETAILER' ? <BalanceBlackIcon /> : <Bank_Icon />}


              </ListItemIcon>
              <ListItemText primary="Balance" sx={{ opacity: open ? 1 : 0, color: userType === "RETAILER" ? "black" : "white" }} />
              {open && balanceOpen ? (
                <ExpandLessIcon />
              ) : open ? (
                <ExpandMoreIcon />
              ) : null}
            </ListItemButton>
            <Collapse in={balanceOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
              {filteredPaths2.map((item) => (
                  <ListItem
                    button
                    key={item.id}
                    sx={{ pl: 4 }}
                    onClick={() => navigate(item.path)}
                    style={{ marginLeft: "10%" }}
                  >
                    {/* <ListItemIcon>{item.icon}</ListItemIcon> */}
                    <ListItemText primary={item.title} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </ListItem>
          {userType === "ADMIN" && (
            <ListItem
              disablePadding
              sx={{
                display: "block",
                backgroundColor: drawerColor,
                color: userType === "RETAILER" ? "black" : "white",
                marginTop: "5%",
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  color: userType === "RETAILER" ? "black" : "white",
                }}
                onClick={handleCompensationClick}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: userType === "RETAILER" ? "black" : "white",
                  }}
                >
                  {userType === 'RETAILER' ? <CompensationBlackIcon /> : <Compensation_Icon />}

                </ListItemIcon>
                <ListItemText
                  primary="Compensation"
                  sx={{
                    opacity: open ? 1 : 0, color: userType === "RETAILER" ? "black" : "white",
                  }}
                />
                {open && compensationOpen ? (
                  <ExpandLessIcon />
                ) : open ? (
                  <ExpandMoreIcon />
                ) : null}
              </ListItemButton>
              <Collapse in={compensationOpen} timeout="auto" unmountOnExit>
                <List sx={{ marginLeft: "7%" }}>
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        color: userType === "RETAILER" ? "black" : "white",
                      }}
                      onClick={handleCommissionClick}
                    >
                      <ListItemText
                        primary="Commission"
                        sx={{
                          opacity: open ? 1 : 0, color: userType === "RETAILER" ? "black" : "white",
                        }}
                      />
                      {open && commissionOpen ? (
                        <ExpandLessIcon />
                      ) : open ? (
                        <ExpandMoreIcon />
                      ) : null}
                    </ListItemButton>
                  </ListItem>
                  <Collapse in={commissionOpen} timeout="auto" unmountOnExit>
                    <List sx={{ marginLeft: "7%" }}>
                      {commissionpaths.map((item) => (
                        <ListItem
                          button
                          key={item.id}
                          sx={{ pl: 4 }}
                          onClick={() => navigate(item.path)}
                        >
                          {/* <ListItemIcon>{item.icon}</ListItemIcon> */}
                          <ListItemText primary={item.title} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={handleSurchargeClick}
                    >
                      <ListItemText
                        primary="Surcharge"
                        sx={{
                          opacity: open ? 1 : 0, color: userType === "RETAILER" ? "black" : "white",
                        }}
                      />
                      {open && surchargeOpen ? (
                        <ExpandLessIcon />
                      ) : open ? (
                        <ExpandMoreIcon />
                      ) : null}
                    </ListItemButton>
                  </ListItem>
                  <Collapse in={surchargeOpen} timeout="auto" unmountOnExit>
                    <List sx={{ marginLeft: "7%" }}>
                      {surchargepaths.map((item) => (
                        <ListItem
                          button
                          key={item.id}
                          sx={{ pl: 4 }}
                          onClick={() => navigate(item.path)}
                        >
                          {/* <ListItemIcon>{item.icon}</ListItemIcon> */}
                          <ListItemText style={{ color: userType === "RETAILER" ? "black" : "white" }} primary={item.title} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>

                </List>
              </Collapse>
            </ListItem>
          )}
          <ListItem
            disablePadding
            sx={{
              display: "block",
              backgroundColor: drawerColor,
              color: userType === "RETAILER" ? "black" : "white",
              marginTop: "5%",
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                color: userType === "RETAILER" ? "black" : "white",
                px: 2.5,
              }}
              onClick={handleReportClick}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: userType === "RETAILER" ? "black" : "white",
                }}
              >
                {userType === 'RETAILER' ? <ReportBlackIcon /> : <Report_Icon />}


              </ListItemIcon>
              <ListItemText primary="Reports" sx={{ opacity: open ? 1 : 0, color: userType === "RETAILER" ? "black" : "white" }} />
              {open && reportOpen ? (
                <ExpandLessIcon />
              ) : open ? (
                <ExpandMoreIcon />
              ) : null}
            </ListItemButton>
            <Collapse in={reportOpen} timeout="auto" unmountOnExit>
              <List sx={{ marginLeft: "7%" }}>
                {reportsPaths.map((item) => (
                  <ListItem
                    button
                    key={item.id}
                    onClick={() => navigate(item.path)}
                    sx={{ pl: 4 }}
                  >
                    {/* <ListItemIcon>{item.icon}</ListItemIcon> */}
                    <ListItemText primary={item.title} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </ListItem>

          <ListItem
            disablePadding
            sx={{ display: "block" }}
            style={{
              backgroundColor: drawerColor,
              color: userType === "RETAILER" ? "black" : "white",
              marginTop: "5%",
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                const link = document.createElement("a");
                link.href = `${process.env.PUBLIC_URL}/IpaisaApplication.pdf`; 
                link.download = "IpaisaApplication.pdf"; 
                link.click();
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: userType === "RETAILER" ? "black" : "white",
                }}
              >
                {userType === "RETAILER" ? <DownloadIcon1 style={{color:'black'}}/> : <DownloadIcon1 style={{color:'white'}} />}
              </ListItemIcon>
              <ListItemText
                primary="Download Agreement"
                sx={{
                  opacity: open ? 1 : 0,
                  color: userType === "RETAILER" ? "black" : "white",
                }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            sx={{ display: "block" }}
            style={{
              backgroundColor: drawerColor,
              color: userType === "RETAILER" ? "black" : "white",
              marginTop: "5%",
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handleOpenKycVerification}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: userType === "RETAILER" ? "black" : "white",
                }}
              >
                {userType === 'RETAILER' ? <HowToRegIcon style={{color:'black'}} /> : <HowToRegIcon style={{color:'white'}}/>}

              </ListItemIcon>
              <ListItemText
                primary="KYC Verification"
                sx={{
                  opacity: open ? 1 : 0, color: userType === "RETAILER" ? "black" : "white",
                }}
              />
            </ListItemButton>
          </ListItem>
        
          {userType === "ADMIN" && (
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            style={{
              backgroundColor: drawerColor,
              color: userType === "RETAILER" ? "black" : "white",
              marginTop: "5%",
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handleOpenChangeParent}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: userType === "RETAILER" ? "black" : "white",
                }}
              >
               {userType === 'RETAILER' ? <ChangeCircleOutlinedIcon style={{color:'black'}} /> : <ChangeCircleOutlinedIcon style={{color:'white'}} />}

              </ListItemIcon>
              <ListItemText
                primary="Change Parent"
                sx={{
                  opacity: open ? 1 : 0, color: userType === "RETAILER" ? "black" : "white",
                }}
              />
            </ListItemButton>
          </ListItem>
          )}

          {userType !== "ADMIN" && (
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              style={{
                backgroundColor: drawerColor,
                color: userType === "RETAILER" ? "black" : "white",
                marginTop: "5%",
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={handleOpenRequestPayment}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {userType === 'RETAILER' ? <NoteOutlinedIcon style={{color:'black'}}/> : <NoteOutlinedIcon style={{color:'white'}} />}

                </ListItemIcon>

                <ListItemText
                  primary="Payment Request"
                  sx={{ opacity: open ? 1 : 0, color: userType === "RETAILER" ? "black" : "white" }}
                />
              </ListItemButton>
            </ListItem>
          )}

          <ListItem
            disablePadding
            sx={{ display: "block" }}
            style={{
              backgroundColor: drawerColor,
              color: userType === "RETAILER" ? "black" : "white",
              marginTop: "5%",
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handleOpenCreateTicket}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: userType === "RETAILER" ? "black" : "white",
                }}
              >
                {userType === 'RETAILER' ? <TicketBlackIcon /> : <CreateTicket_Icon />}

              </ListItemIcon>
              <ListItemText
                primary="Create Ticket"
                sx={{
                  opacity: open ? 1 : 0, color: userType === "RETAILER" ? "black" : "white",
                }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            sx={{
              display: "block",
              backgroundColor: drawerColor,
              color: userType === "RETAILER" ? "black" : "white",
              marginTop: "5%",
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                color: userType === "RETAILER" ? "black" : "white",
              }}
              onClick={handleSettingClick}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: userType === "RETAILER" ? "black" : "white",
                }}
              >
                {userType === 'RETAILER' ? <SettingsBlackIcon /> : <Setting_Icon />}


              </ListItemIcon>
              <ListItemText primary="Settings" sx={{ opacity: open ? 1 : 0, color: userType === "RETAILER" ? "black" : "white", }} />
              {open && settingOpen ? (
                <ExpandLessIcon />
              ) : open ? (
                <ExpandMoreIcon />
              ) : null}
            </ListItemButton>
            <Collapse in={settingOpen} timeout="auto" unmountOnExit>
              <List sx={{ marginLeft: "7%" }}>
                {settingsPaths.map((item) => (
                  <ListItem
                    button
                    key={item.id}
                    onClick={() => navigate(item.path)}
                    sx={{ pl: 4, color: userType === "RETAILER" ? "black" : "white", }}
                  >
                    {/* <ListItemIcon>{item.icon}</ListItemIcon> */}
                    <ListItemText primary={item.title} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </ListItem>
          <ListItem
            disablePadding
            sx={{
              display: "block",
              backgroundColor: drawerColor,
              color: userType === "RETAILER" ? "black" : "white",
              marginTop: "5%",
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                color: userType === "RETAILER" ? "black" : "white",
              }}
              onClick={handleSupportClick}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: userType === "RETAILER" ? "black" : "white",
                }}
              >
                {userType === 'RETAILER' ? <SupportBlackIcon /> : <Support_Icon />}

              </ListItemIcon>
              <ListItemText primary="Support" sx={{ opacity: open ? 1 : 0, color: userType === "RETAILER" ? "black" : "white", }} />
              {open && supportOpen ? (
                <ExpandLessIcon />
              ) : open ? (
                <ExpandMoreIcon />
              ) : null}
            </ListItemButton>
            <Collapse in={supportOpen} timeout="auto" unmountOnExit>
              <List sx={{ marginLeft: "7%" }}>
                {supportPaths.map((item) => (
                  <ListItem
                    button
                    key={item.id}
                    onClick={() => navigate(item.path)}
                    sx={{ pl: 4, color: userType === "RETAILER" ? "black" : "white", }}
                  >
                    {/* <ListItemIcon>{item.icon}</ListItemIcon> */}
                    <ListItemText primary={item.title} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </ListItem>
          <ListItem
            disablePadding
            sx={{
              display: "block",
              marginTop: "15%",
              backgroundColor: drawerColor,
              color: userType === "RETAILER" ? "black" : "white",
            }}
            onClick={handleLogout}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                width: fixedWidth, // Apply fixed width
                color: userType === "RETAILER" ? "black" : "white",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: userType === "RETAILER" ? "black" : "white",
                }}
              >
                {userType === 'RETAILER' ? <LogoutBlackIcon /> : <Logout_Icon />}

              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0, color: userType === "RETAILER" ? "black" : "white", }} />
            </ListItemButton>
          </ListItem>

        </List>
      </Drawer>
    </BackdropFilterContainer>
  );
}
