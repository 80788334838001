import React, { useState } from 'react';
import { Container, Grid, Paper, Typography, Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material';
import NewPayInOutTopComp from './NewPayInOutTopComp';
import { makeStyles } from '@mui/styles';
import { styled, keyframes } from '@mui/system';
import { PartnerArrowIcon } from '../../res/NewWebIcons';


const useStyles = makeStyles(() => ({
    tableRow: {
        '& > *': {
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
    },
    evenRow: {
        backgroundColor: '#D0EFFF',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    }
}));

const NewCommissionReport = () => {
    const classes = useStyles();
    const totalCommission = 100000.0;
    const totalPaid = 100000.54;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const columns = [
        { id: 'srNo', label: 'Sr No' },
        { id: 'date', label: 'Date' },
        { id: 'transactionId', label: 'Transaction Id' },
        { id: 'partnerName', label: 'Partner Name' },
        { id: 'partnerAccountNumber', label: 'Account Number' },
        { id: 'commissionAmount', label: 'Commission Amount' },
        { id: 'totalCommissionAmount', label: 'Total Commission Amount' },
        { id: 'description', label: 'Description' },
        { id: 'commissionStatus', label: 'Status' },
        { id: 'senderName', label: 'Sender Name' },
    ];

    const rows = [
        {
            srNo: 1,
            date: '2024-05-01',
            transactionId: 'TXN123456',
            partnerName: 'Partner 1',
            partnerAccountNumber: 'ACC123456',
            commissionAmount: 100.0,
            totalCommissionAmount: 12345.67,
            description: 'Commission for April',
            commissionStatus: 'Paid',
            senderName: 'Sender 1',
        },
        // Add more rows as needed
    ];
  
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
  return (
    <Container sx={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
       <Grid style={{display:'flex', alignItems:'center', gap:'1%', justifyContent:'start'}}>
                <Typography color='textSecondary' style={{fontFamily:'sans-serif', fontSize:'14px'}}>Report</Typography>
                <PartnerArrowIcon/>
                <Typography color='textSecondary' style={{fontFamily:'sans-serif', fontSize:'14px'}}>Commission Report</Typography>
            </Grid>

      <Grid sx={{ marginTop: '1%' }}>
        <Typography
          sx={{
            fontWeight: 'bold',
            fontFamily: 'sans-serif',
            fontSize: '19px',
            color: '#343C6A',
          }}
        >
          Commission Report
        </Typography>
      </Grid>

      <Grid container justifyContent="center" alignItems="center" sx={{ marginTop: '3%', flexDirection: { xs: 'column', md: 'row' } }}>
        <Paper
           sx={{
            padding: { xs: '1rem', sm: '1.5rem', md: '2rem' }, // Adjusting padding responsively
            background: 'linear-gradient(135deg, #413DFF, #547AFF)',
            borderRadius: '12px',
            width: { xs: '100%', sm: '75%', md: '45%' },
            textAlign: 'center',
            display: 'flex',
            justifyContent: { xs: 'center', md: 'space-around' },
            alignItems: 'center',
            flexDirection: { xs: 'column', md: 'row' },
            marginBottom: { xs: '1rem', md: 0 },
            boxSizing: 'border-box', // Ensures padding is included in width calculation
          }}
          elevation={3}
        >
          <Grid item xs={12} md={5} sx={{ textAlign: 'center' }}>
            <Typography
              sx={{
                fontFamily: 'sans-serif',
                fontSize: '16px',
                color: 'white',
              }}
            >
              Total Commission
            </Typography>
            <Typography
              sx={{
                marginTop: '1rem',
                fontFamily: 'sans-serif',
                fontSize: '20px',
                color: 'white',
              }}
            >
              ₹ {totalCommission}
            </Typography>
          </Grid>

          <Divider
            orientation="horizontal"
            flexItem
            sx={{
              display: { xs: 'block', md: 'none' },
              backgroundColor: 'white',
              width: '80%',
              marginX: 'auto', // Center horizontally
              marginY: '1rem',
            }}
          />

          <Divider
            orientation="vertical"
            flexItem
            sx={{
              display: { xs: 'none', md: 'block' },
              backgroundColor: 'white',
              height: '60px',
            }}
          />

          <Grid item xs={12} md={5} sx={{ textAlign: 'center' }}>
            <Typography
              sx={{
                fontFamily: 'sans-serif',
                fontSize: '16px',
                color: 'white',
              }}
            >
              Total Paid
            </Typography>
            <Typography
              sx={{
                marginTop: '1rem',
                fontFamily: 'sans-serif',
                fontSize: '20px',
                color: 'white',
              }}
            >
              ₹ {totalPaid}
            </Typography>
          </Grid>
        </Paper>
      </Grid>

        <Grid sx={{marginTop:'3%'}}>
            <Grid>
                <Grid sx={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'1%'}}>
                    <Grid>
                    <Typography sx={{fontWeight:'bold', fontFamily:'sans-serif', fontSize:'19px', color:'#343C6A'}}>History</Typography>
                    </Grid>
                
                        {/* <Button sx={{textTransform:'none', backgroundColor:'#027BFE', color:'#FFFFFF', borderRadius:'10px', display:'flex', alignItems:'center', justifyContent:'center', fontFamily:'sans-serif', fontSize:'14px', width:'20%', gap:'5%'}}
                        onClick={generatePDF}
                        >
                        <DownloadIcon/>
                        Download PDF File</Button> */}
                </Grid>
            </Grid>

            <TableContainer component={Paper} sx={{ marginTop:'2%',maxWidth: '100%', overflowX: 'auto', borderRadius:'12px 12px 0px 0px'  }}>
                        <Table>
                            <TableHead sx={{backgroundColor: '#027BFE'}}>
                                <TableRow>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Sr. No.</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Transaction Date</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Transaction Id</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Partner Name</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Partner Account Number</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Commission Amount</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Total Commission Amount</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Description</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Commission Status</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Sender Name</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                    <TableRow key={row.id} 
                                    className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{index + 1}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.date}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.transactionId}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.partnerName}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.partnerAccountNumber}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.commissionAmount}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.totalCommissionAmount}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.description}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.commissionStatus}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.senderName}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
        </Grid>

    </Container>
  );
};

export default NewCommissionReport;
