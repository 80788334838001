import React, { useState, useEffect } from 'react'
import NewPayInOutTopComp from './NewPayInOutTopComp'
import SearchIcon from "@mui/icons-material/Search";
import { Button, Container, Grid, IconButton, InputBase, Typography, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material'
import { DownloadIcon, PartnerArrowIcon } from '../../res/NewWebIcons';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import jsPDF from 'jspdf';
import iPaisaLogo from '../Assets/iPaisaLogo/iPaisaLogo.jpg';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    evenRow: {
        backgroundColor: '#D0EFFF',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    },
    PayInText: {
        color: 'green',
    },
}));

const NewPayIn = () => {

    const [searchQuery, setSearchQuery] = useState('');
    const [showSearchInput, setShowSearchInput] = useState(false);
    const classes = useStyles();
    const [payInData, setPayInData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPayInData = async () => {
            const jwtToken = localStorage.getItem('jwtToken');
            try {
                const response = await axios.get('https://api.ipaisa.co.in/myapp/auth/easTxnDetails', {
                    headers: { Authorization: `Bearer ${jwtToken}` }
                });
                const sortedData = (response.data.body || []).sort((a, b) => new Date(b.addedon) - new Date(a.addedon));
                setPayInData(sortedData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching PayIn data:', error);
                setLoading(false);
            }
        };

        fetchPayInData();
    }, []);

    const formatDate = (dateTimeString) => {
        if (!dateTimeString) return ''; // Return an empty string if dateTimeString is null or undefined

        const [datePart, timePart] = dateTimeString.split('T');
        if (!datePart || !timePart) return dateTimeString; // Return the original string if it can't be split

        const [year, month, day] = datePart.split('-');
        const formattedDate = `${day}-${month}-${year}`;

        return `${formattedDate} ${timePart}`;
    };

    const username = localStorage.getItem("username");
    const exportCSV = () => {
        if (!payInData || payInData.length === 0) {
            toast('No transaction data available to generate CSV.');
            return;
        }
        const headers = [
            'Sr No.', 'Transasction-Type', 'Transaction ID', 'UTR No.', 'Status', 'Amount', 'Added On',
        ];

        const csvRows = [];
        csvRows.push(headers.join(',')); // Add headers to CSV

        payInData.forEach((row, index) => {
            const values = [
                index + 1,
                'Pay-In',
                row.txnid,
                row.easepayid,
                row.status,
                row.amount,
                row.addedon,
            ];
            csvRows.push(values.join(',')); // Add each row's data to CSV
        });

        const csvContent = csvRows.join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', 'PayInData.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };



    const generatePDF = () => {
        if (!payInData || payInData.length === 0) {
            toast('No transaction data available to generate PDF.');
            return;
        }

        const doc = new jsPDF();
        const logo = new Image();
        logo.src = iPaisaLogo;

        logo.onload = () => {
            doc.addImage(logo, 'PNG', 10, 10, 30, 30); // Adjust position and size as needed
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(16);
            doc.text('Transaction Statement', 50, 20);
            doc.setFontSize(12);
            doc.text('24 Jun, 2024 - 24 Jul, 2024', 50, 30);

            const tableColumn = ["Date", "Transaction Details", "Type", "Amount"];
            const tableRows = [];

            payInData.forEach(row => {
                const rowData = [
                    formatDate(row.addedon),
                    row.type === 'Pay-Out'
                        ? `Paid to : ${row.reciverName}\nTransaction ID : ${row.txnId}\nUTR No. : ${row.utrNo}\nPaid by : ${row.senderName}\nAccount Number : ${row.accountNumber}\nTransfer Amount : ${row.amount}\nWallet Opening Balance : ${Number(row.walletOpeningBalance).toFixed(2)}\nWallet Closing Balance : ${Number(row.walletClosingBalance).toFixed(2)}\nStatus : ${row.status}`
                        : `Received from ${row.firstname}\nTransaction ID ${row.txnid}\nUTR No. ${row.easepayid}\nCredited to ${username}`,
                    row.type,
                    `${row.amount}`
                ];
                tableRows.push(rowData);
            });

            doc.autoTable({
                head: [tableColumn],
                body: tableRows,
                startY: 50,
                styles: {
                    fontSize: 10,
                    cellPadding: 3,
                    overflow: 'linebreak',
                },
                headStyles: {
                    fillColor: [22, 160, 133],
                    textColor: [255, 255, 255],
                    fontSize: 12,
                },
            });

            doc.save('Transaction_Statement.pdf');
        };
    };

    if (loading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
                <CircularProgress />
            </Grid>
        );
    }
    return (
        <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
            <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Report</Typography>
                <PartnerArrowIcon />
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Pay In Report</Typography>
            </Grid>

            <Grid>
                <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1%' }}>
                    <Grid>
                        <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '25px', color: '#343C6A' }}>Pay In Report</Typography>
                    </Grid>

                    <Grid style={{ display: 'flex', gap: '3%' }}>
                        <Grid style={{
                            display: 'flex',
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            maxWidth: '60%',
                            borderRadius: 10,
                            border: '1px solid rgba(0, 0, 0, 0.3)',
                        }}>

                            <IconButton>
                                <SearchIcon style={{ color: 'rgba(0, 0, 0, 0.5)' }} />
                            </IconButton>
                            <InputBase
                                style={{ color: 'rgba(0, 0, 0, 3)' }}
                                placeholder="Search"
                                sx={{ width: '100%' }}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                onClick={() => setShowSearchInput(!showSearchInput)}
                            />
                        </Grid>
                        <Button style={{ textTransform: 'none', backgroundColor: '#027BFE', color: '#FFFFFF', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingRight: '5%', fontFamily: 'sans-serif', fontSize: '14px', width: '80%', gap: '5%' }}
                            onClick={generatePDF}
                        >
                            <DownloadIcon />
                            Download PDF File</Button>
                        <Button style={{ textTransform: 'none', backgroundColor: '#027BFE', color: '#FFFFFF', borderRadius: '10px', paddingRight: '5%', fontFamily: 'sans-serif', fontSize: '14px', width: '80%', gap: '5%' }} onClick={exportCSV}>
                            <DownloadIcon /> Download CSV File
                        </Button>

                    </Grid>
                </Grid>
            </Grid>

            <Grid style={{ marginTop: '3%' }}>
                <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', borderRadius: '12px 12px 0px 0px' }}>
                    <Table>
                        <TableHead style={{ backgroundColor: '#027BFE', color: 'white' }}>
                            <TableRow>
                                <TableCell style={{ color: 'white' }} align="center">Sr. No.</TableCell>
                                <TableCell style={{ color: 'white' }} align="center">Type</TableCell>
                                <TableCell style={{ color: 'white' }} align="center">Txn ID</TableCell>
                                <TableCell style={{ color: 'white' }} align="center">UTR No</TableCell>
                                <TableCell style={{ color: 'white' }} align="center">Status</TableCell>
                                <TableCell style={{ color: 'white' }} align="center">Date</TableCell>
                                <TableCell style={{ color: 'white' }} align="center">Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {payInData && payInData.length > 0 ? (
                                payInData.map((txn, index) => (
                                    <TableRow key={txn.id} className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                        <TableCell align="center">{index + 1}</TableCell>
                                        <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                            <TableCell align="center" style={{ color: 'green', fontWeight: 'bold', fontSize: '17px' }}>Pay-In</TableCell>
                                            <CallReceivedIcon style={{ color: 'green' }} />
                                        </Grid>
                                        <TableCell align="center">{txn.txnid}</TableCell>
                                        <TableCell align="center">{txn.easepayid}</TableCell>
                                        <TableCell align="center">{txn.status}</TableCell>
                                        <TableCell align="center">{formatDate(txn.addedon)}</TableCell>
                                        <TableCell align="center">{txn.amount}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={8} align="center">
                                        No transactions found.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>


        </Container>
    )
}

export default NewPayIn
